h1, h2, h3, h4, h5 {
  font-family: 'Raleway', sans-serif;
  margin: 0;
  font-weight: 500 !important;
}

p, div {
  font-family: 'Raleway', sans-serif;
  font-size: 0.9rem;
}

p {
  line-height: 1.5rem;
  margin-bottom: 2rem;
}

.p-card .p-card-body {
  p {
    margin-bottom: 1rem;
  }
}

.letter-space-20 {
  letter-spacing: 0.2em;
}

.text-color-light-gray {
  color: #4e4e4e;
}

.section-margin {
  margin: 0 auto;
  @media screen and (max-width: 600px) {
    margin: 0 auto;
  }
}

.video-js .vjs-dock-title {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13.2px;
}

h3.text-5xl.font-normal.mb-1 {
  margin-bottom: 4px !important;
}

p.text-xl.mt-0.mb-5.text-center, p.text-xl.mb-6 {
  margin-bottom: 48px !important;
}