.provider-form {
  max-width: 1480px;
  width: 100%;
  svg.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
    color: var(--primary-color) !important;
  }
  svg.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    color: var(--primary-color) !important;
  }
  &__basic-btn.MuiButtonBase-root {
    color: var(--primary-color) !important;
    border-radius: 3px;
    padding: 0.65rem 0;
    &:hover,
    &:focus {
      background-color: var(--primary-color);
      color: var(--primary-color-text) !important;
    }
    &.back-btn {
      color: var(--primary-color) !important;
      padding-left: 0;
      padding-right: 0;
      margin: auto 0;
      min-width: max-content;
      &:hover, &:focus, &:focus-within, &:active {
        color: var(--primary-color) !important;
        border: none;
        background-color: transparent !important;
      }
    }
  }
  .MuiStepLabel-label {
    text-transform: capitalize;
  }
  .p-button {
    padding: 0.35rem 1rem;
    box-shadow: none !important;
    font-weight: 600;
    height: auto;
  }
  .p-message-wrapper {
    font-family: Montserrat, sans-serif;
    text-transform: uppercase;
    font-weight: 400;
  }

  .react-tel-input .form-control {
    max-height: 2.5rem;
    height: auto !important;
  }
  #email-help, #token-help {
    font-family: Montserrat, sans-serif;
  }
  .p-inputtext.p-disabled.p-filled {
    color: #000 !important;
    opacity: 1 !important;
  }
  .p-error {
    font-size: 1.25rem;
  }
}

.css-4ff9q7.Mui-active {
  color: var(--primary-color) !important;
}

.css-4ff9q7.Mui-completed {
  color: #0b9a15 !important;
}

.signup-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 1rem;
  .affiliate-btn {
    background-color: var(--primary-darker-color);
    color: var(--primary-color-text);
    border: none;
  }
  .provider-btn {
    background-color: var(--primary-color);
    color: var(--primary-color-text);
    border: none;
  }
  .provider-btn,
  .affiliate-btn {
    max-width: 45%;
    min-width: 45%;
    height: 3.5rem;
  }
}

.toggle-card-button {
  background: #fff !important;
  border: 1px solid var(--surface-200) !important;

  &:hover {
    border: 1px solid var(--surface-400) !important;
  }
  &.error-border {
    border: 1px solid #f44336 !important;
  }

  &.selected, &.checked-wrapper {
    border: 1px solid var(--surface-900) !important;
    background: #f3f3f3 !important;
  }
  .p-text-secondary {
    font-family: Montserrat, sans-serif !important;
    font-weight: 400 !important;
    margin: 0 0 !important;
    text-align: left !important;
    color: var(--surface-900) !important;
    text-transform: uppercase !important;
  }
  .secondary-label {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-family: Oswald, sans-serif !important;
    margin: 0 0.5rem 0 0;
    padding: 0;
  }
  &.disclaimer-layout {
    border-radius: 3px;
    .p-checkbox {
      width: 20px !important;
    }
    .field-checkbox {
      flex-direction: column-reverse;
      align-items: flex-start;
      margin-bottom: 0;
      @media only screen and (min-width: 961px) {
        flex-direction: row;
        align-items: center;
      }
    }
  }
}
