.gradient {
  &.blue-lightblue {
    background: linear-gradient(90.34deg, #2E4FC6 0.27%, #8886FF 96.01%);
    h4 {
      font-size: 40px;
      line-height: 49px;
      text-align: center;
      font-weight: normal;
    }
  }
  .mb-64 {
    margin-bottom: 64px !important;
    @media only screen and (min-width: 768px) {
      margin-bottom: 0;
    }
  }
}
