.p-m80-d100 {
  padding-top: 100px;
  padding-bottom: 100px;
  @media only screen and (max-width: 676px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.p-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.p-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}