.ShopBlogs {
  .grid {
    min-height: 353px;
    align-items: center;

    @media (max-width: 576px) {
      gap: 20px;
    }
  }
  &__view-more-btn .c-button-black.p-button {
    background-color: var(--primary-color) !important;
    border: 1px solid var(--primary-color);
    color: var(--primary-color-text);

    &:hover {
      background-color: var(--primary-color-text) !important;
      border: 1px solid var(--primary-color) !important;
      color: var(--primary-color);
    }
  }
  .slick-arrow {
    background: var(--surface-ground);
    width: 40px;
    height: 40px;
    font-size: 25px;
    color: var(--text-color);
    line-height: 40px;
    text-align: center;
    transition: background-color 0.3s, color 0.3s, opacity 0.5s;
    top: 47%;

    &:hover {
      background: var(--surface-100);
    }

    &:before {
      display: none;
    }

    @media (max-width: 576px) {
      width: 40px;
      height: 40px;
      font-size: 25px;
      line-height: 40px;
    }
  }

  .slick-next {
    right: -15px;
    @media (min-width: 776px) {
      right: -40px;
    }
  }

  .slick-prev {
    z-index: 1;
    left: -15px;
    @media (min-width: 776px)  {
      left: -40px;
    }
  }
}
