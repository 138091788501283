.product-slick-slider {
  position: relative;
  background: var(--surface-ground);
  // transform: scale(0.8);
  // width: 90%;
  margin: 0 auto 2rem;
  @media screen and (min-width: 989px) {
    // transform: scale(1);
    // width: 90%;
    margin: 0 auto 0rem;
  }

  .slick {
    .slick-slider {
      position: relative;
      display: block;
      margin: 30px auto;
    }

    &-dots {
      @media screen and (min-width: 989px) {
        bottom: unset;
      }
    }
    &-next {
      // right: -15px;
      // @media (min-width: 776px) {
      //   right: 0px;
      // }
    }

    &-prev {
      z-index: 1;
      // left: -15px;
      // @media (min-width: 776px)  {
      //   left: 0px;
      // }
    }
    &-arrow {
      display: none !important;
    }
  }
  .slick-slide {
    margin: 0 auto !important;
    img {
      object-fit: cover !important;
      aspect-ratio: 1;
 
      margin: 0 50px;
      display: block;
      margin: 0 auto !important;
      width: 100%;
      @media screen and (min-width: 961px) {
        // max-height: 30rem;
      }
      &:focus-visible {
        outline: 2px solid #005fcc !important;
      }
    }
  }

  .slick-thumb li.slick-active img {
    border: 1px solid var(--c-primary-color);
  }

  .slick-thumb img {
    border: 1px solid rgba(255, 255, 255, 0);
    transition: border 200ms ease;
  }
  .slick-dots {
    display: flex !important;
    flex-wrap: wrap !important;
    width: 100%;
    justify-content: center;
    padding: 1rem;
    background: var(--surface-ground);

    li {
      display: inline-table;
      width: max-content;
    }
  }
  .slick-thumb-link {
    height: 64px;
    width: 64px;
    // &:nth-child(n+4) {
    //   margin: 1rem 0 !important;
    // }
    img,
    div {
      border-radius: 2px !important;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
.thumbnails-slider {
  display: flex !important;
  margin-left: 0px !important;

  .slick-list {
    width: 100% !important;
    .slick-track {
      justify-content: space-between;
      display: flex;
      flex-direction: row;
    }
    @media screen and (min-width: 989px) {
      width: 564px !important;
    }
  }
  .slick-slide {
    img {
      object-fit: cover;
    }
  }
}

.slick-dots {
  bottom: -80px !important;
  @media screen and (max-width: 1199px) {
    padding: 0;
    bottom: -1px !important;
  }
  li button:before {
    color: var(--primary-color) !important;
  }
  .slick-next {
    // right: -5px;
  }
  .slick-prev {
    // left: -5px !important;
    z-index: 1;
  }
  .slick-arrow {
    background: transparent;
    width: 40px;
    height: 40px;
    font-size: 25px;
    color: var(--primary-color);
    line-height: 40px;
    text-align: center;
    transition: background-color 0.3s, color 0.3s, opacity 0.5s;
    // top: 55%;

    &:hover {
      background: var(--surface-100);
    }

    &:before {
      display: none;
    }

    @media (max-width: 576px) {
      width: 40px;
      height: 40px;
      font-size: 25px;
      line-height: 40px;
    }
  }
}
