.footer__social-links {
  display: flex;
  margin-top: 20px !important;
  gap: 7px;
  margin-bottom: 20px;
  flex-wrap: wrap;

  button {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;

    min-width: 37.1px;
    aspect-ratio: 1;

    svg {
      color: var(--primary-color-text);
    }

    &:hover,
    &:focus-visible {
      background-color: var(--primary-color);
      border-color: var(--primary-color);

      svg {
        color: var(--primary-color-text);
      }
    }
  }
}
