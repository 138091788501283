.product-carousel {
  width: 100%;

  .p-carousel-prev,
  .p-carousel-next {
    position: absolute;
    top: 0;
    right: 0;
    margin: -0.5rem !important;
    color: var(--primary-color) !important;
  }

  .p-carousel-prev {
    right: 1.5rem;
  }
  // .p-carousel-indicator {
  //   display: none !important;
  //   // button.p-link {
  //   // }
  // }

  ul.p-carousel-indicators.p-reset {
    display: none !important;
  }

  @media screen and (max-width: 360px) {
    .p-carousel-prev,
    .p-carousel-next {
      display: none;
    }
  }
}
