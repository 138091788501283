.product-features {

  button.p-button.p-component.order-btn {
    color: var(--primary-color-text) !important;
    background: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
  }
  .feature-header {
    &__content {
      width: 80%;
      max-width: 1080px;
      align-items: center;
    }
    &__content{
      .form-contact-wrapper > .form-contact-section{
        max-width: 100%;

        div {
          padding-top: 0 !important;
          div {
            padding-left: 0;
            
          }
        }
      }
    }
    .subtitle {
      color: var(--primary-color);
    }
    a.learn-more-button, button.learn-more-button {
      border: none !important;
      background: none !important;
      color: var(--primary-color) !important;
      font-weight: 700 !important;
      transition: 200ms ease color;
      text-decoration: none;
      padding: 0.5rem 1rem;
      font-family: 'Raleway', sans-serif !important;
      display: flex;
      align-items: center;
      letter-spacing: 2px;
      svg {
        transform: scale(0.75);
      }
      &:hover {
        background: none !important;
        border: none !important;
        color: var(--c-primary-darker-color) !important;
      }
    }
  }
  .divider {
    width: 100%;
    height: 5rem;
    background-color: var(--10x-brand);
  }
  .border--color {
    border-color: var(--primary-color) !important;
  }
  .steps {
    &__header {
      margin: auto;
      display: flex;
      flex-direction: column;
      width: 80%;
      max-width: 1080px;
    }

    &__section {
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80%;
      max-width: 1080px;
    }

    &__title {
      color: var(--primary-color);
      text-transform: uppercase;
    }
  }

  .steps.dark {
    background-color: black;
    .steps__text {
      color: rgb(237, 233, 233);
    }
    .border--color {
      border-color: rgba(255, 255, 255, 0.3) !important;
    }
    .border--color:last-child {
      border: none;
    }
  }

  &__bottom {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    max-width: 1080px;

    .product-feature-related-item-card {
      display: flex;
      flex-direction: column;
      align-items: center;

      .p-card-body {
        padding: 0 1rem 1rem 1rem;
        margin: 0;
      }
      .p-card-content {
        padding: 0;
      }
      .p-card-footer {
        display: flex;
        justify-content: center;
        padding: 0;
      }
    }
  }
  .react-media-player {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media only screen and (min-width: 768px) {
      &::before {
        content: '';
        position: absolute;
        top: -5px;
        left: 75px;
        height: 25px;
        width: 150px;
        background-color: #111;
        z-index: 99;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
  .react-media-player,
  .react-media-player > * {
    height: 100%;
    width: 100%;
    border-radius: 30px;
    @media only screen and (min-width: 768px) {
      height: calc(100% - 10px);
      width: calc(100% - 10px);
    }
  }
}
