.video-iframe-container { 
  position: relative; 
  padding-bottom: 56.25%; 
  padding-top: 30px; 
  height: 0; 
  overflow: hidden; 
  &.vertical-video {
    padding-bottom: 200%; 
  }
} 

.video-iframe-container iframe, .video-iframe-container object, 
.video-iframe-container embed { 
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%; 
}