.product-info__container {
  margin-top: 4px;
  padding: 0px;
  .product-info-row {
    font-size: 0.75rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
   @media (min-width: 1200px) {
    flex-direction: row;
     align-items: center;
   }
    background-color: var(--surface-ground);
    &:nth-child(even) {
      background-color: transparent;
    }
    .font-bold {
      padding: 2px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 16px;
      min-width: 120px;
      border-right: 0.5px dotted var(--surface-border);
    }
    .product-info-data {
      user-select: all;
      padding: 2px 10px;
    }
  }
}
