.marketplace-nav {
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1010 !important;
  background-color: var(--surface-card);
  transition: padding 200ms ease;
  padding: 0 !important;
  .p-menubar-root-list {
    z-index: 500 !important;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    .marketplace-logo {
      transition: height 200ms ease;
      &.down-size {
        @media only screen and (max-width: 350px) {
          height: 25px;
        }
        height: 30px;
        @media only screen and (min-width: 960px) {
          height: 50px;
        }
      }
      &.full-size {
        @media only screen and (max-width: 350px) {
          height: 35px;
        }
        height: 50px;
        @media only screen and (min-width: 960px) {
          height: 78px;
        }
      }
    }
    li:nth-of-type(2):not(.p-menuitem) {
      order: -1;
    }
    .p-menubar {
      display: flex;
      justify-content: center;
      background-color: transparent;
      border: none;
      padding-top: 0;
      padding-bottom: 0;
      height: 100%;
      .p-menuitem-link {
        // color: #e9ecef !important;
      }
    }
    .p-menuitem {
      text-transform: uppercase;
      transition: all 200ms ease-in-out;
      > a {
        border-radius: 0px !important;
        padding-left: 0.2rem !important;
        padding-right: 0.2rem !important;
        font-size: 11px !important;
        @media only screen and (min-width: 1200px) {
          padding-left: 1rem !important;
          padding-right: 1rem !important;
        }
      }
      &:hover,
      &:focus-within,
      &-active {
        .p-menuitem-text {
          // color: #1c1c1c !important;
        }
        // background-color: #e9ecef !important;
      }
    }
    .p-submenu-list {
      background-color: transparent;
      transition: all 200ms ease;
      max-width: 100%;
      padding: 0 !important;
      @media only screen and (min-width: 960px) {
        position: fixed !important;
        width: 175px !important;
      }
      @media only screen and (min-width: 1200px) {
        width: 205px !important;
      }
      .p-menuitem {
        width: 100%;
      }
    }
    .p-menuitem-text {
      // color: #e9ecef !important;
      letter-spacing: 2px;
    }
    @media only screen and (min-width: 961px) {
      li:nth-of-type(2):not(.p-menuitem) {
        order: 0;
        flex: 1;
      }
      .p-menubar-root-list {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        text-align: left;
        li {
          height: 100%;
        }
        li > a {
          height: 100%;
          @media only screen and (max-width: 1035px) and (min-width: 960px) {
            padding-left: 0.2rem !important;
            padding-right: 0.2rem !important;
          }
        }
      }
    }
    @media only screen and (min-width: 960px) {
      .p-menubar-root-list {
        justify-content: right;
      }
    }
    @media only screen and (max-width: 960px) {
      li:nth-of-type(1) {
        flex: 1;
        justify-content: center;
        padding: 0;
      }
      .p-menubar {
        position: relative;
        background-color: transparent;
        .p-menubar-root-list {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100vw;
          // background-color: #1c1c1c;
          padding: 28px !important;
          > .p-menuitem:not(:last-of-type) {
            margin-bottom: 8px;
          }
          > .p-menuitem:last-of-type {
            margin-top: 50px;
            position: relative;
            &::before {
              content: '';
              position: absolute;
              width: 100vw;
              height: 1px;
              top: -29px;
              left: -28px;
              // background-color: #1c1c1c;
            }
          }
        }
      }
    }
    .cart-item {
      &:hover {
        background-color: #e9ecef !important;
        .pi-shopping-cart {
          color: #1c1c1c !important;
        }
      }
    }
    &:after {
      position: absolute;
      background-color: #1c1c1c;
      inset: 0 0 0 0;
      z-index: 1002;
      height: 100%;
      width: 100%;
    }
  }
  .cart-wrapper {
    position: relative;
    margin: 0 0.8rem;

    i {
      z-index: 2;
      position: relative;
    }
    span {
      background-color: #14a8a6;
      color: #e9ecef;
      font-weight: bold;
      top: -14px;
      right: -14px;
      z-index: 2;
      opacity: 1;
      transition: opacity 200ms;
      width: 25px;
      position: absolute;
      line-height: 25px;
      border-radius: 50%;
      text-align: center;
      font-size: 12px;
      letter-spacing: 0;
      &.hide {
        opacity: 0;
      }
    }
  }
  .p-menubar-button {
    text-decoration: none;
    position: relative;
    z-index: 1005;
    &:before {
      content: '';
      position: fixed;
      inset: -10px 0 10px 0;
      height: max-content;
      width: 100vw;
      background: #1c1c1c;
      z-index: 600;
    }
    .hamburger-icon {
      position: relative;
      height: 2px;
      width: 20px;
      background: #e9ecef;
      transition-duration: 0.3s;
      z-index: 700;
      &:before {
        position: absolute;
        content: '';
        height: 2px;
        width: 20px;
        background-color: #e9ecef;
        top: -6px;
        left: 0;
        transition-duration: 0.3s;
      }
      &:after {
        position: absolute;
        content: '';
        height: 2px;
        width: 20px;
        background-color: #e9ecef;
        top: 6px;
        left: 0;
        transition-duration: 0.3s;
      }
    }
  }
  @media only screen and (max-width: 960px) {
    .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
      animation: 0.5s effectIn 1;
    }
    .p-menubar:not(.p-menubar-mobile-active) .p-menubar-root-list {
      display: flex;
      flex-direction: column;
      animation: 0.5s effectOut 1;
      transform: translateY(-150%);
    }
  }
  .p-menubar.p-component {
    @media only screen and (max-width: 960px) {
      position: relative;
      z-index: 2;
      will-change: transform;
      &:before {
        background: rgba(0, 0, 0, 0);
        transition: all 200ms ease;
        content: '';
      }
      &.p-menubar-mobile-active {
        .hamburger-icon {
          background-color: transparent;
          &:before {
            transform: rotate(45deg);
            transform-origin: center;
            top: 50%;
          }
          &:after {
            transform: rotate(-45deg);
            transform-origin: center;
            top: 50%;
          }
        }
      }
    }
  }
  .user-menubar {
    @media only screen and (min-width: 961px) {
      padding-right: 0 !important;
    }
    .pi-angle-down {
      transform: rotate(0deg);
      transition: transform 200ms ease;
    }
    .p-menuitem-active {
      .pi-angle-down {
        transform: rotate(180deg);
      }
      a {
        color: #1c1c1c;
        background-color: #e9ecef !important;
      }
      .p-submenu-list {
        background-color: rgba(30, 30, 30, 0.97) !important;
        width: 100%;
        a {
          background-color: #1c1c1c !important;
          span {
            color: #e9ecef !important;
            letter-spacing: 0.5px;
          }
          &:hover,
          &:focus-within {
            background-color: rgba(#353535, 0.5) !important;
          }
          &:focus-visible {
            outline: solid #14a8a6 1px !important;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 961px) {
    .p-menubar.p-component.p-menubar-mobile-active.user-menubar {
      .p-menubar-root-list {
        &:before {
          position: fixed;
          inset: 0 0 0 0;
          background: #1c1c1caf;
          height: 200vh;
          width: 100vw;
          z-index: -1;
          content: '';
        }
      }
    }
  }
  @keyframes effectIn {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes effectOut {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-150%);
    }
  }

  .p-menubar.p-component {
    @media only screen and (max-width: 960px) {
      position: relative;
      z-index: 2;
      &:before {
        background: rgba(0, 0, 0, 0);
        transition: all 200ms ease;
        content: '';
      }
    }
  }

  .p-toast {
    @media only screen and (max-width: 768px) {
      width: 20rem !important;
    }
  }

  .nav-email-item {
    .p-menuitem-link {
      @media only screen and (min-width: 961px) and (max-width: 1100) {
      }
    }
    a.p-menuitem-link .p-menuitem-text {
      @media only screen and (min-width: 960px) {
        white-space: initial;
        overflow: initial;
        text-overflow: initial;
        max-width: initial;
      }
      @media only screen and (min-width: 961px) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 10ch;
      }
      @media only screen and (min-width: 1200px) {
        max-width: 15ch;
      }
      @media only screen and (min-width: 1500px) {
        max-width: 30ch;
      }
    }
  }
}
