.nav-tree-table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__container {
    width: calc(100% - 14px);
    -webkit-transition: font-size, 0.5s ease;
    -moz-transition: font-size 0.5s ease;
    -o-transition: font-size 0.5s ease;
    transition: font-size 0.5s ease;

    .p-treetable .p-treetable-tbody > tr {
      line-height: 16px;
      td {
        padding: 0.2rem 1rem !important;
        border: none;
      }
    }

    .p-treetable .p-treetable-thead > tr > th,
    .p-treetable .p-treetable-tbody > tr {
      font-weight: 400 !important;
      font-size: 13px;
      text-transform: uppercase;
      letter-spacing: 1.2px;
      color: var(--text-color-secondary);
      background-color: rgba(var(--surface-800), 0.25);
      padding: 0.5rem 1rem;
    }
    .p-treetable .p-treetable-thead > tr > th {
      border: none;
      color: var(--text-color);
      padding: 0px 24px;
      position: relative;

      &:before {
        position: absolute;
        margin-bottom: -12px;
        background: linear-gradient(
          to right,
          var(--primary-color) 50px,
          var(--surface-border) 50px
        );
        height: 3px;
        content: '';
        bottom: 0;
        right: 0;
        left: 1.6rem;
      }
    }

    .p-treetable .p-treetable-tbody::before {
      content: '';
      display: block;
      height: 30px;
    }
    // line heigh 18px

    .p-treetable-tbody > tr > td {
      display: flex;
      flex-wrap: nowrap;
      align-items: baseline;
      button.p-treetable-toggler.p-link.p-unselectable-text {
        margin-right: 0 !important;
      }
      i {
        font-size: 0.75rem;
      }
    }
    &__categories {
      display: inline-block;
      overflow: hidden;
      max-width: 80%;
      text-overflow: ellipsis;
      &:hover,
      &:focus,
      &:active {
        color: var(--text-color);
        font-weight: 600;
      }
    }

    .p-treetable .p-treetable-tbody > tr.p-highlight,
    .p-treetable
      .p-treetable-tbody
      > tr.p-highlight:focus
      .p-treetable.p-treetable-hoverable-rows
      .p-treetable-tbody
      > tr:not(.p-highlight):hover,
    .p-treetable.p-treetable-hoverable-rows
      .p-treetable-tbody
      > tr:not(.p-highlight):active,
    .p-treetable.p-treetable-hoverable-rows
      .p-treetable-tbody
      > tr:not(.p-highlight):focus {
      background-color: rgba(var(--surface-800), 0.25);
      color: var(--primary-color);
      font-weight: 600 !important;
      outline-color: var(--primary-color) !important;
    }
  }

  .user-info {
    padding: 0;
    width: 100%;
    margin: 0;
    border: none;
    color: var(--text-color) !important;
    background: transparent;

    ul {
      width: 100%;
      .p-menuitem-active > .p-submenu-list {
        display: block;
        left: 0;
        top: 30px;
      }
    }
    li {
      padding: 0rem;
    }
    a.p-menuitem-link,
    li {
      color: var(--text-color) !important;
      text-transform: capitalize;
      font-size: 12px !important;
      line-height: 44px;
      letter-spacing: 2px;
      font-weight: 400;
    }
    .p-tieredmenu {
      background: transparent;
      border: 1px solid var(--text-color-secondary);
    }
    .nav-email-item {
      > a .p-menuitem-text {
        text-transform: lowercase;
      }

      .p-menuitem-link {
        .p-menuitem-text,
        .p-menuitem-icon {
          color: var(--text-color);
          letter-spacing: 2px;
          font-weight: 400;
          font-size: 12px;
        }
      }
      .p-menuitem-link {
        @media only screen and (min-width: 961px) and (max-width: 1100) {
        }
      }
      a.p-menuitem-link .p-menuitem-text {
        @media only screen and (min-width: 500px) {
          white-space: initial;
          overflow: initial;
          text-overflow: initial;
          max-width: initial;
        }
        @media only screen and (max-width: 499px) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 25ch;
        }
      }
      &.p-menuitem-active {
        .p-submenu-list.p-submenu-list-flipped {
          box-shadow: 4px 4px 5px rgb(0 0 0 / 15%) !important;
          z-index: 10;
          .p-menuitem .p-menuitem-link .p-menuitem-text {
            color: var(--text-color);
          }
        }
      }
    }

    .p-tieredmenu .p-menuitem-active > .p-submenu-list-flipped {
      left: 0 !important;
    }

    .p-tieredmenu .p-menuitem-active > .p-submenu-list {
      display: block;
      left: 0 !important;
      top: 100%;
    }

    .p-submenu-icon {
      display: none;
    }
    .p-tieredmenu {
      padding: 0;
      color: var(--text-color-secondary);
      border: none;
      width: auto;
    }
  }
}
