#vendor-logos {
  padding: 10px 0;
  overflow: hidden;

  .container {
    border-top: 1px solid var(--surface-card);
  }

  .partner {
    text-align: center;

    img {
      width: 100%;
      margin: 0 auto;
    }
  }

  .slick-slider {
    button {
      color: var(--primary-color) !important;
      &:before {
        color: var(--primary-color) !important;
      }
      &:focus-visible {
        outline: 2px solid #005fcc !important;
      }
    }
  }

  .slick-arrow {
    background: var(--surface-ground);
    width: 40px;
    height: 40px;
    font-size: 25px;
    color: var(--text-color);
    line-height: 40px;
    text-align: center;
    transition: background-color 0.3s, color 0.3s, opacity 0.5s;
    top: 55%;

    &:hover {
      background: var(--surface-100);
    }

    &:before {
      display: none;
    }

    @media (max-width: 576px) {
      width: 40px;
      height: 40px;
      font-size: 25px;
      line-height: 40px;
    }
  }

  .slick-next {
    right: -15px;
    @media (min-width: 776px) {
      right: 0px;
    }
  }

  .slick-prev {
    z-index: 1;
    left: -15px;
    @media (min-width: 776px) {
      left: 0px;
    }
  }

  .slick-track {
    display: flex;
    align-items: center;
  }

  .slick-slide img {
    max-width: 300px !important;
    min-width: 300px;
    object-fit: cover;
  }
}
