.react-switch {
  margin-top: 10px; 
  outline: none;
    &:focus-within {
      outline: 1px solid #005FCC !important;
    }
  @media screen and (min-width: 991px) {
    margin-top: initial !important;
  }
  &.dark {
    .react-switch-bg {
      background: var(--primary-color) !important;
    }
    .react-switch-handle {
      > div {
        top: -2px !important;
        left: 1.5px !important;
      }
    }
  }
  &.light {
    .react-switch-bg {
      background-color: var(--primary-color) !important;
    }
    .react-switch-handle {
      background: var(--primary-color-text);
      > div {
        top: -2px !important;
        left: -0.5px !important;
      }
    }
  }

  .react-switch-handle {
    box-shadow: rgb(0 0 0 / 60%) 0px 0px 1px !important;
    > div {
      top: -2px !important;
      left: -1px !important;
    }

    &:active {
      box-shadow: 0px 0px 0.5px 2px rgba(0, 0, 0, 0.1) !important;
      @media screen and (min-width: 991px) {
        box-shadow: 0px 0px 0.5px 2.5px rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .react-switch-bg {
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      top: 1px !important;
      @media screen and (min-width: 960px) {
      }
      svg {
        display: block;
      }

      &:first-child {
        svg {
          color: var(--primary-color-text);
        }
      }
      &:nth-child(2) {
        color: var(--primary-color-text);
      }
    }
  }
  .svg-inline--fa {
    height: 0.7em;
    margin: 0 0 0.2rem 0.2rem;
    color: var(--primary-color-text);
    @media (max-width: 992px) {
    }
  }
}
