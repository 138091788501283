.cart-page__toast {
  width: calc(100vw - 4rem);
  margin: 1rem 1rem 0 0;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (min-width: 576px) {
  .cart-page__toast {
    width: 300px;
  }
}

.cart-page__slider {
  .slick {
    &-arrow {
      color: var(--primary-color);
      filter: unset !important;
  
      &::before {
        color: var(--text-color);
      }
    }
  }
  .slick-dots {
    bottom: -50px;
  }
}