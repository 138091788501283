#shop-testimonials {
  .p-carousel-content {
    .p-carousel-container {
      background: transparent;
      min-height: 14rem;

      &::before,
      &::after {
        display: none;
      }

      .p-carousel-prev,
      .p-carousel-next {
        display: none;
      }

      .p-carousel-items-container {
        padding: 0;
      }
    }

    .p-carousel-indicators {
      display: flex !important;

      .p-carousel-indicator {
        button {
          width: 10px;
          height: 10px;
          opacity: 0.5;
          border-radius: 50%;
          background: transparent;
          z-index: 100;
          border: 2px solid var(--surface-800);
          transition: all 0.2s ease-in-out;
        }

        &.p-highlight {
          button {
            opacity: 1;
            background: var(--surface-800);
          }
        }
      }
    }
  }

  .testimonial-card {
    background: var(--surface-ground) !important;
    border: 0.5px solid var(--surface-500);
    padding: 30px !important;
    .testimonial-text {
      color: var(--text-color-secondary);
      font-size: 14px;
      line-height: 28px;
      margin: 0;
      font-style: italic;
    }

    .author {
      padding-top: 16px;
      color: var(--text-color-secondary);

      span {
        display: block !important;
        line-height: 1.2;

        &:first-of-type {
          font-family: 'Montserrat', sans-serif;
          font-size: 13px;
          color: var(--text-color);
          margin-bottom: 5px;
        }
        &:last-of-type {
          font-size: 13px;
        }
      }
    }
  }
}
