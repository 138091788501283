#shop-blog-explorer {
  max-width: 1480px;
  margin: 0 auto;
  padding: 50px 0;

  .header {
    color: var(--text-color);
  }

  .spinner-wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .paginator-wrapper {
    margin-top: 50px;
  }

  .block {
    background: var(--surface-ground);
    padding: 15px;
    margin: 0 0 50px;
  }
  .p-paginator {
    width: 100%;
    background: var(--surface-ground);
    border: none;
  }

  .blog-item-col {
    padding: 0.2rem !important;

    .p-card-body {
      padding: 0;
    }
    .p-card-content {
      padding-top: 0;

      .content {
        padding: 0 10px;
      }
    }
  }

  .p-dataview {
    .p-dataview-content {
      background: transparent !important;
      width: 100%;
      max-width: 1480px !important;

      .grid {
        margin-right: -0.2rem !important;
        margin-left: -0.2rem !important;
        margin-top: -0.2rem !important;
      }
    }
  }

  .slick-arrow {
    background: var(--surface-ground);
    width: 40px;
    height: 40px;
    font-size: 25px;
    color: var(--text-color);
    line-height: 40px;
    text-align: center;
    transition: background-color 0.3s, color 0.3s, opacity 0.5s;
    top: 47%;

    &:hover {
      background: var(--surface-100);
    }

    &:before {
      display: none;
    }

    @media (max-width: 576px) {
      width: 40px;
      height: 40px;
      font-size: 25px;
      line-height: 40px;
    }
  }

  .slick-next {
    right: -15px;
    @media (min-width: 776px) {
      right: -40px;
    }
  }

  .slick-prev {
    z-index: 1;
    left: -15px;
    @media (min-width: 776px)  {
      left: -40px;
    }
  }
}

.slick-slider {
  .blog-item {
    margin: 0 0.5rem;
    padding: 10px;
  }
}
