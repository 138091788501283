.my-message {
  .react-media-player,
  .react-media-player > * {
    height: 100%;
    width: 100%;
  }
  .react-media-player {
    &__wrapper {
      height: 250px;
      max-width: 725px;
      box-shadow: 0px 30px 24px -20px #000000 !important;
      min-width: 80vw;
      @media only screen and (min-width: 576px) {
        height: 450px;
        min-width: 40vw;
      }
    }
    &__buttons {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      width: 100%;
      max-width: 725px;
      margin: 0 auto 48px auto;
      &.video-total-over-3 {
        @media only screen and (min-width: 675px) {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(200px, 1fr) ) ;
        }
      }
      button {
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 2px!important;
        color: black;
        background-color: transparent;
        border: none;
        outline: none;
        padding: 10px;
        cursor: pointer;
        font-size: 10px;
        margin: 0 0px 8px 0px;
        @media only screen and (min-width: 376px) {
          font-size: 12px;
          margin: 0 8px 16px 8px;
        }
        transition: all 200ms ease;
        &.active {
          color: #0400FF;
        }
        &:focus-visible {
          outline: 1px solid #0400FF;
        }
        &:hover {
          color: lighten(#000, 40%) !important;
        }
      }
    }
    .bc-player-default_default .vjs-poster {
      background-color: #000;
    }
  }
}