.p-checkbox {
  margin-right: 0.5rem;
}

.input-mb-05 {
  .p-field {
    margin-bottom: 0.5rem;
  }
}
.form-heading {
  margin-bottom: 2rem;
}

.order--sumary--toggle {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-size: 0.8rem;
  text-align: center;
  @media only screen and (min-width: 320px) {
    text-align: left;
    flex-direction: row;
  }
  @media only screen and (min-width: 360px) {
    flex-direction: row;
    font-size: 1rem;
  }
}

div.enable > a > span.p-accordion-header-text {
  color: var(--primary-color);
}

div.disable > a > span.p-accordion-header-text {
  color: var(--surface-400);
}

.field-radiobutton-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #ced4da;
  padding: 0.5rem 0.5rem;
  border-radius: 3px;
  width: 100%;
  .field-radiobutton {
    width: 100%;
    position: relative;
    &:first-of-type {
      margin: 2px auto 10px 0px;
    }
    margin: 10px auto 2px 0px;
    &.radiobutton-underline {
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 0;
        left: 0;
        bottom: -10px;
        border-bottom: 1px solid #ced4da;
      }
    }
    label {
      width: 100%;
      text-align: left;
      cursor: pointer;
    }
  }
}
// .field-radiobutton {
//   border: 1px solid #ced4da;
//   cursor: pointer;
//   &.selected-radio-button {
//     border: 1px solid var(--primary-color);
//   }
//   width: 100%;
//   background: #ffffff;
//   padding: 0.5rem 0.5rem;
//   border-radius: 3px;
//   label {
//     width: 100%;
//     text-align: left;
//     cursor: pointer;
//   }
// }

.dropdown-checkout-wrapper {
}

// .p-inputtext.p-component.p-autocomplete-input {
//   border-left: 1px solid var(--primary-color);
//   border-top: 1px solid var(--primary-color);
//   border-bottom: 1px solid var(--primary-color);
// }

// .p-button.p-component.p-autocomplete-dropdown.p-button-icon-only {
//   border-right: 1px solid var(--primary-color);
//   border-top: 1px solid var(--primary-color);
//   border-bottom: 1px solid var(--primary-color);
// }

.form-disabled-overlay {
  position: relative;
  &:after {
    cursor: not-allowed;
    position: absolute;
    inset: 0 0 0 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    content: '';
    border-radius: 4px;
  }
}

.date-picker {
  .MuiInputBase-root {
    border-radius: 3px;
    input {
      padding: 1.1rem 0.4rem 0.7rem !important;
    }
  }
  label {
    font-size: 14px;
    top: 14px;
    left: -7px !important;
    text-transform: capitalize;
    font-family: inherit;
  }
  legend {
    display: none;
  }
  fieldset {
    border: 1px solid #ced4da;
    bottom: 6.5px;
  }
  button {
    color: var(--surface-900);
    &:hover {
      background-color: transparent;
    }
  }
}
