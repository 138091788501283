// dark
$dark-primary: #000;
$dark-secondary: #333;
$dark-text: #555;

// light
$white-primary: #fff;
$white-secondary: #ccc;
$white-text: #aaa;
//
$teal-primary: #14a8a6;
$teal-secondary: #007f7d;
$teal-dark: #004f4d;

$focus: #005fcc;
