.exchange-categories {
  .p-submenu-header {
    font-weight: 900;
    color: rgb(20, 20, 20);
    border-bottom: 1px solid #ccc;
    letter-spacing: 0.2em;
    padding: 0.5rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
  }
  .p-menuitem-link {
    padding: 0.5rem 0;
    &:hover {
      background-color: transparent !important;
      .p-menuitem-text {
        color: #999;
      }
    }
    .p-menuitem-text {
      font-size: 13px;
      text-transform: uppercase;
      letter-spacing: 3px;
      color: #555;
      font-weight: 400;
    }
  }
  .p-menu-list > .p-submenu-header:not(:first-of-type) {
    margin-top: 1rem;
  }
}