 .collection__item {  
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 160px;
    @media only screen and (min-width: 768px) {
      height: 280px;
    }
    aspect-ratio: 1;
    width: 100%;
    margin: 0px auto;
    background-color: var(--primary-color);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    transition: all  200ms ease;
    &:after {
      content: '';
      position: absolute;
      inset: 0 0 0 0;
      background-color: #00000078;
      z-index: 1;
      transition: all  200ms ease;
    }
    &:hover, &:focus-visible {
      &:after {
        background-color: #31313178;
      }
    }
  p {
    color: var(--gray-50);
    font-size: 1rem;
    @media only screen and (min-width: 768px) {
      font-size: 1.25rem;
    }
    width: 80%;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    text-align: center;
    margin-bottom: 0rem;
  }
  &.mobile-menu {
    height: 140px;
  }
}