.my-vision {
  overflow: hidden;
  &__bg-image {
    @media only screen and (max-width: 960px) {
        transform: scale(1.35);
        background-position: left center;
    }
    background-position: 95px center;
    background-repeat: no-repeat;
    transform: scale(1.25);
    background-size: cover;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &.drbuttar, &.sayerji {
      transform: scale(1.5);
    }
    &.eileenmckusick, &.loislaynee, &.leilacentner, &.theduttons {
      background-position: center center;
      background-size: cover;
      transform: initial;
    }
    &.wildeinfluence {
      background-position: center center;
      background-size: cover;
      transform: initial;
      @media only screen and (max-width: 576px) {
        background-position: top left;
      }
    }
    &.dontclick {
      background-position: center right;
      background-size: cover;
      transform: initial;
      @media only screen and (max-width: 576px) {
        background-position: top right;
      }
    }
  }
  &__gradient {
    width: 100%;
    @media only screen and (max-width: 576px) {
      padding-top: 44px;
      padding-bottom: 44px;
    }
  }

  .vision-text-container{
    h3 {
      text-align: left !important;
    }
  }

  p:last-of-type {
    margin-bottom: 0;
  }
  ul {
    li {
      line-height: 1.8rem;
    }
  }
}

.mb-44 {
  margin-bottom: 44px !important;
  @media only screen and (min-width: 768px) {
    margin-bottom: 0 !important;
  }
}