#marketplace-footer {
  .newsletter-form {
    .p-input-icon-left {
      margin-bottom: 16px;
      display: block;

      i {
        margin-top: -0.5rem;
        left: 0.8rem;
      }

      .p-inputtext {
        width: 100%;
        border-color: var(--text-color-secondary);
        padding-left: 38.5px !important;
        border: 1px solid var(--text-color-secondary);
        background-color: transparent;
        padding: 0.5rem 16px;
        box-shadow: none;
        border-radius: 0;
        color: var(--text-color) !important;
        transition: border-color 200ms ease-in-out;

        &:focus {
          border-color: var(--text-color) !important;
        }
        &:focus-visible {
          outline: 2px solid var(--blue-300) !important;
        }
      }
    }

    button {
      background-color: var(--primary-color);
      color: var(--primary-color-text);
      font-size: 11px;
      padding: 10px 25px;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.14em;
      border: 2px solid transparent;
      border-radius: 0;

      &:hover,
      &:focus-visible {
        color: var(--primary-color);
        background-color: var(--surface-200);
      }
    }
  }
}
