.image-container {
  width: 100%;
  padding: 16px 0px;
  background-color: #ffffff;
}

.image-divider {
  padding: 16px;
  max-width: 80%;
  display: flex;
  margin: auto;
}

.form-product-request-wrapper {
  background-color: #ffffff !important;
  width: 100%;
}
.form-contact-wrapper.light-background {
  background-color: var(--surface-f);
  .btn-submit.light-background {
    color: var(--primary-color-text) !important;
    background: rgba(209, 36, 42, 1) !important;
    border: 1px solid rgba(209, 36, 42, 1) !important;
  }
}

.form-contact-wrapper {
  background-color: #d1242a;
  width: 100%;
}

.form-product-request-section,
.form-contact-section {
  max-width: 80%;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin: auto;
}

.form-product-request-section-title,
.form-contact-section-title {
  font-weight: 900 !important;
  font-size: 40px !important;
  font-family: 'Montserrat', sans-serif !important;
  line-height: 1.1em !important;
  color: #000000;
}

.form-product-request-section-title {
  color: #000000;
}
.form-contact-section-title {
  color: #ffffff;
  text-align: center;
  margin-bottom: 32px;
}

.form-product-request-section-subtitle,
.form-contact-section-subtitle {
  font-size: 28px !important;
  font-family: 'Oswald', sans-serif !important;
  font-weight: 400 !important;
}

.form-product-request-section-subtitle {
  color: #000000;
}
.form-contact-section-subtitle {
  color: #ffffff !important;
  margin: 16px 0px;
}

.form-contact-section-instructions {
  color: #ffffff !important;
  font-size: 16px;
  font-weight: 400 !important;
}

.form-section-image-container {
  margin: auto;
  img {
    width: 300px;
    height: 100%;
  }
}

.btn-submit {
  margin: 16px 0px !important;
  background: #000000 !important;
  border-color: #000000 !important;
  color: #ffffff !important;
  span {
    font-size: 22px !important;
    line-height: 22px !important;
    font-weight: 700 !important;
    font-family: Oswald, sans-serif !important;
  }
}
