.orders {
  .p-tabview {
    .p-tabview-nav-link {
      font-weight: normal !important;
      font-size: 14px;
    }
  }
  .p-unselectable-text.p-tabview-selected.p-highlight > a.p-tabview-nav-link {
    color: var(--text-color) !important;
  }
  div#pr_id_1_content_1.p-tabview-panel {
    background-color: var(--surface-card);
  }
  .p-tabview-nav-link {
    align-items: baseline;
  }
  .p-tabview-nav-link > i {
    font-weight: 100;
    margin-right: 0.3rem;
  }
}
