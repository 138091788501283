#breadcrumbs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #151515;
  position: relative;
  color: #fff;
  background-size: cover;
  padding: 70px 0;
  background-position: center center;
  text-align: center;
  min-height: 35vh;
  @media screen and (min-width: 960px) {
    background-attachment: fixed;
    min-height: 35vh;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    z-index: 1;
   background-color: rgba(0,0,0, 0.25);
  }

  > * {
    z-index: 1;
  }

  h2 {
    letter-spacing: 5px;
    margin: 0;
    position: relative;
    font-size: 40px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 1.8;
    opacity: 1;
  }
  h4 {
    margin-top: 0;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: rgba(255, 255, 255, 0.85);
    line-height: 1.5;
    margin-bottom: 10px;
  }

  @media (max-width: 576px) {
    padding: 25px 0;

    h2 {
      font-size: 30px;
    }
    h4 {
      font-size: 12px;
    }
  }
}
