#parralax-bg {
  overflow: hidden;
  background-color: var(--primary-dark-color);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 45vh;
  @media screen and (min-width: 960px) {
    min-height: 55vh;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--maskbg);
    z-index: 0;
  }

  &.dark-soft {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
      z-index: 0;
    }
  }

  .content {
    text-align: center;
    z-index: 1;

    h3 {
      text-align: center;
      font-size: 2rem;
      margin-bottom: 2rem;
      letter-spacing: 0.1em;
      @media only screen and (min-width: 767px) {
        letter-spacing: 0.2em;
      }
      text-transform: uppercase;
      color: #fff !important;
      font-weight: 600;

      @media (max-width: 576px) {
        font-size: 1.5rem;
      }

      @media screen and (min-width: 640px) {
        font-size: 2.4rem;
        margin-bottom: 2.4rem;
      }

      @media screen and (min-width: 960px) {
        font-size: 64px;
        margin-bottom: 33px;
      }
    }
  }
  .main {
    width: 100%;
    height: 100vh;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
}
