@use '../../shop-home-page/shop-scss/shop-colors.scss' as *;

#cart-icon-generator {
  .cart-icon {
    &__header {
      color: var(--primary-color);
    }
    &__input::placeholder {
      opacity: 0.6;
    }
    .p-button {
      color: var(--primary-color-text);
      background-color: var(--primary-color) !important;
      border: none;
      &:active {
        background-color: var(--primary-color) !important;
        border: none;
      }
    }
    &__color-picker {
      margin-bottom: 1rem !important;
      width: 15%;
      height: 6.5vh;
      border: 1px solid #aaa;
    }
    &__dropdown-item {
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 2;
      font-weight: 700;
    }
  }
  
  #bgColorInput,
  .p-inputtext:enabled:hover,
  .p-inputtext:enabled:active,
  .p-inputtext:enabled:focus {
    border-color: var(--primary-color);
    &:hover,
    &:active,
    &:focus {
      border-color: var(--primary-dark-color);
    }
  }
  
  // styling for icon cart preview:
  .p-dropdown.cart-icon__dropdown {
    width: 15%;
  }
  .custom-cart {
    position: relative;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .custom-cart__wrapper {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 65%;
    bottom: 55%;
    overflow: hidden;
    border-radius: 50%;
    z-index: 2;
  }
  .custom-cart__svg {
    width: 30px;
    height: auto;
    z-index: 0;
  }
  .cart__button {
    border: none;
    background: transparent;
    cursor: pointer;
    min-width: max-content;
    padding: 0;
    margin: 0;
    position: relative;
    &:hover {
      background: transparent !important;
    }
  }
}
