@import '../../index.scss';

#marketplace-footer {
  width: 100%;
  margin: 0 auto;
  background-color: var(--surface-50);
  padding: 80px 0 0;
  .footer-about-us-section {
    max-width: 1480px;
    margin: 0 auto;
    padding: 0;
  }

  .marketplace-footer__social-links {
    display: flex;
    margin-top: 20px !important;
    gap: 7px;
    margin-bottom: 20px;
    flex-wrap: wrap;

    button {
      background-color: var(--surface-200);
      border-color: var(--surface-200);
      min-width: 37.1px;
      aspect-ratio: 1;

      svg {
        color: var(--primary-color);
      }

      &:hover,
      &:focus-visible {
        background-color: var(--primary-color);
        border-color: var(--primary-color);

        svg {
          color: var(--primary-color-text);
        }
      }
    }
  }
  .about-us {
    min-height: 50%;
    max-height: 55%;
  }

  .title {
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
    font-size: 14px !important;
    letter-spacing: 0.2em;
    margin: 0 0 16px;
    color: var(--text-color);
    text-transform: uppercase;
    font-weight: 600 !important;
    line-height: 1.5;
  }

  p {
    font-size: 14px;
    color: var(--text-color);
    font-weight: normal;
    line-height: 26px;
  }
  ul {
    list-style: none;
    padding: 0;

    li {
      border-bottom: none;
      padding: 4px 0;
      line-height: 24px;

      &:first-child {
        padding-top: 0 !important;
      }

      a {
        font-size: 14px !important;
        color: var(--text-color-secondary);
        font-weight: normal !important;
        line-height: 26px !important;
        text-decoration: none;
        outline: none;
        text-transform: capitalize !important;
        letter-spacing: unset !important;
        -webkit-transition: color 0.2s ease-in-out;
        -moz-transition: color 0.2s ease-in-out;
        -o-transition: color 0.2s ease-in-out;
        transition: color 0.2s ease-in-out;

        &:hover,
        &:focus-within {
          color: var(--text-color) !important;
        }
        // &:focus-within {
        //   outline: 1px solid #0400FF !important;
        // }
        &:focus-visible {
          outline: 2px solid #005fcc !important;
        }
      }
    }
  }

  @media (max-width: 576px) {
    .grid {
      gap: 20px;
    }
  }
}

#bottom-footer {
  background-color: var(--surface-100);
  padding: 0 0 20px;
  @media (max-width: 991px) {
    margin-bottom: 80px;
  }
  margin-bottom: 0px;

  .bottom-footer-links {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin-bottom: 10px;

        &:after {
          content: '';
          border-right: 1px solid var(--surface-500);
          margin: 0 7px 0 10px;
        }

        &:last-child:after {
          border: none;
        }

        a {
          color: var(--text-color-secondary);
          text-decoration: none;
          text-transform: uppercase;
          font-size: 11px !important;
          letter-spacing: 0.1em !important;

          &:hover,
          &:focus-within {
            color: var(--text-color);
          }
          &:focus-visible {
            outline: 2px solid #005fcc !important;
          }
        }
      }
    }
    span {
      line-height: 1;
      margin-bottom: 0px;
      display: inline-block;
      color: var(--text-color-secondary);
      font-size: 13px;
      letter-spacing: 0.1em;

      a {
        color: var(--text-color-secondary);
        text-decoration: none;
        text-transform: uppercase;
        font-size: 13px !important;
        letter-spacing: 0.1em !important;

        &:hover,
        &:focus-within {
          color: var(--text-color);
        }
        &:focus-visible {
          outline: 2px solid #005fcc !important;
        }
      }
    }
  }

  .footer-payment-systems {
    display: flex;
    gap: 5px;

    svg {
      font-size: 30px;
      color: var(--text-color);
    }
  }

  @media (max-width: 576px) {
    text-align: center;

    .grid {
      gap: 20px;
    }
  }
  .footer-nav {
    margin: auto;
    background-color: var(--surface-100);
    border-bottom: 0.5px solid var(--text-color);
    .grid {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0 0 0 0;
      @media (max-width: 576px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
      }
    }
  }

  .title {
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
    font-size: 14px !important;
    letter-spacing: 0.2em;
    margin: 0 0 16px;
    color: var(--text-color);
    text-transform: uppercase;
    font-weight: 600 !important;
    line-height: 1.5;
  }

  p {
    font-size: 14px;
    color: var(--text-color);
    font-weight: normal;
    line-height: 26px;
  }
  ul {
    list-style: none;
    padding: 0;

    li {
      border-bottom: none;
      padding: 4px 0;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;

      &:first-child {
        padding-top: 0 !important;
      }

      a {
        font-size: 14px !important;
        color: var(--text-color-secondary);
        font-weight: normal !important;
        line-height: 26px !important;
        text-decoration: none;
        outline: none;
        text-transform: capitalize !important;
        letter-spacing: unset !important;
        -webkit-transition: color 0.2s ease-in-out;
        -moz-transition: color 0.2s ease-in-out;
        -o-transition: color 0.2s ease-in-out;
        transition: color 0.2s ease-in-out;

        &:hover,
        &:focus-within {
          color: var(--text-color) !important;
        }
        // &:focus-within {
        //   outline: 1px solid #0400FF !important;
        // }
        &:focus-visible {
          outline: 2px solid #005fcc !important;
        }
      }
    }
  }

  @media (max-width: 576px) {
    .grid {
      gap: 20px;
    }
  }
}

