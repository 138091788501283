.loader {
  &:not(.no-min-height) {
    min-height: 80vh;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  .loader--content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    span {
      margin-top: 16px;
      font-weight: 500;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }
  }
  &.product-height {
    min-height: initial;
    max-height: 200px;
    display: flex;
  }
}

.p-progress-spinner-circle {
  stroke-dasharray: 89, 200;
  stroke-dashoffset: 0;
  stroke: var(--primary-color) !important;
  animation: p-progress-spinner-dash 1.5s ease-in-out infinite,
    p-progress-spinner-color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes p-progress-spinner-color {
  0% {
    stroke: var(--primary-color) !important;
  }
  100% {
    stroke: var(--primary-dark-color) !important;
  }
}
