.exchange-card {
  transition: box-shadow 500ms ease!important;
  border-radius: 20px!important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 3px 6px rgba(0, 0, 0, 0.23) !important;
  &:hover {
    box-shadow: 0px 19px 38px -5px rgba(0, 0, 0, 0.3), 0px 15px 12px -5px rgba(0, 0, 0, 0.22) !important;
  }
  img  {
    height: 12rem;
    object-fit: cover;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    display: block;
  }
  .p-card-content {
    padding: 0px !important;
    display: flex;
    flex-direction: column;
    .exchange-name {
      font-weight: bold;
    }
    .exchange-description {
      font-weight: normal;
      p {
        display: -webkit-box !important;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical !important;  
        overflow: hidden !important;
        padding: 0px !important;
        margin: 0px !important;
      }
    }
  }
}

.dataview-demo {
  .p-dataview-grid .p-dataview-content {
    .p-grid.p-nogutter {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .p-dataview-list {
    picture {
      display: flex;
      align-items: center;
    }
    img {
      object-fit: contain;
      border-radius: 8px;
      display: block;
      width: 100%;
    }
  }
}