.product-review-modal {
  .write-review-btn {
    background-color: #FB6900 !important;
    border: none;
    cursor: pointer;
    letter-spacing: 2px;
    font-size: 13px;
    font-weight: 700;
    &:hover, &:focus-within {
      background: #C83600 !important;
    }
    &:focus-visible {
      outline: 2px solid #005FCC !important;
    }
  }
  .p-rating {
    display: flex;
    align-items: center;
  }
  .p-rating-icon {
    color: #f0b232 !important;
  }
  .p-dialog-content {
    padding: 0 1.5rem  1.5rem !important;
  }
}