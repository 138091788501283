// @import '../pages/shop-home-page/shop-scss/shop-index.scss';

.product-description__container {
  .p-inputnumber {
    .p-button,
    .p-button:hover {
      background-color: transparent !important;
      border: 1px solid var(--surface-border) !important;
      border-radius: 0;
    }
    .p-button-icon {
      transform: scale(0.7);
      &::before {
        color: var(--text-color);
      }
    }
    .p-inputtext {
      max-width: 40px;
      border-color: var(--surface-border) !important;
      box-shadow: none !important;
      text-align: center;
    }
  }
  .p-pricing-text {
    font-size: 2rem;
    margin: 0.15rem 0 0 0 !important;
    color: var(--primary-color);
  }
  .product-categories {
    font-size: 0.75rem !important;
  }
  .product-short-description {
    margin: 1rem 0 !important;
    color: var(--text-color-secondary) !important;
  }
  .p-product-vendor {
    font-size: 0.75rem;
  }
  .product-description-social-icons {
    justify-content: center !important;
    font-size: 36px;
    color: var(--primary-color);
  }

  .btn--height {
    height: 3rem;
    border-radius: 3px;
    span.p-dropdown-label.p-inputtext {
      padding: 0.85rem !important;
    }
  }
  .visit-website-btn {
    width: 100%;
    height: 3rem;
    margin: 0 !important;
    background-color: var(--surface-400) !important;
    color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
    &:hover,
    &:focus-within {
      background-color: var(--primary-color) !important;
      color: var(--primary-color-text) !important;
      border: 1px solid var(--surface-400) !important;
    }
  }
  .add-to-cart-button {
    background-color: #fb6900 !important;
    border: none;
    cursor: pointer;
    letter-spacing: 2px;
    font-size: 13px;
    font-weight: 700;

    &:hover,
    &:focus-within {
      background: #c83600 !important;
    }
    &:focus-visible {
      outline: 2px solid var(--primary-color) !important;
    }
  }
  .view-cart-button {
    font-size: 13px;
    font-weight: 900;
    color: var(--primary-color);
    text-transform: uppercase;
    letter-spacing: 2px;
    border: 1px solid var(--primary-color);
    background-color: transparent !important;
    cursor: pointer;
    &:hover,
    &:focus-within {
      opacity: 0.9;
      color: var(--primary-color) !important;
    }
  }
  .p_m-0 {
    p {
      margin: 0 !important;
    }
  }
}

.category-link {
  text-decoration: none;
  letter-spacing: 0.25px !important;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  color: var(--primary-color) !important;
  text-transform: capitalize;
  &:after {
    display: inline-block;
    white-space: pre;
    content: ',';
  }
  &:last-of-type {
    &:after {
      content: '';
    }
  }
  &:hover,
  &:focus-visible {
    color: var(--primary-color) !important;
  }
}

.product-description__toast {
  width: calc(100vw - 4rem);
  margin: 0 auto;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (min-width: 576px) {
  .product-description__toast {
    width: 300px;
  }
}
