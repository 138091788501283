#partners {
  padding: 10px 0;
  overflow: hidden;

  .container {
    border-top: 1px solid var(--surface-card);
  }

  .partner {
    text-align: center;

    img {
      width: 100%;
      max-width: 220px;
      margin: 0 auto;
    }
  }

  .slick-slider {
    button {
      &:before {
        color: var(--text-color);
      }
      &:focus-visible {
        outline: 2px solid #005FCC !important;
      }
    }
  }
}
