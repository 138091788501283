#HeroCarousel {
  .slick-slider {
    &:hover {
      .slick-arrow {
        opacity: 1;
      }
    }
    .slick-slide {
      transition: opacity 2000ms ease-out 1000s,
        visibility 2000ms ease-out 1000s;
    }
    .slick-dots {
      position: absolute;
      z-index: 10;
      bottom: 1rem !important;
      left: 0;
      margin: 0 auto;
      padding: 0;
      li {
        button {
          border-radius: 50%;
          width: 10px !important;
          height: 10px !important;
          border: 2px solid #ffffff !important;
          &::before {
            display: none;
          }
        }

        &.slick-active {
          button {
            background-color: #fff;
          }
        }

        &:hover {
          button {
            background-color: #fff;
          }
        }
      }
    }

    .slick-arrow {
      background: rgba(0, 0, 0, 0.15);
      width: 100px;
      height: 100px;
      font-size: 30px;
      color: #fff;
      line-height: 100px;
      text-align: center;
      opacity: 0;
      transition: background 0.3s, color 0.3s, opacity 0.5s;

      &:hover {
        background: var(--surface-card);
        color: var(--text-color);
      }

      &:before {
        display: none;
      }

      @media (max-width: 576px) {
        width: 40px;
        height: 50px;
        font-size: 25px;
        line-height: 53px;
      }
    }

    .slick-next {
      right: 0px;
    }

    .slick-prev {
      left: 0px;
      z-index: 1;
    }
  }

  .Hero-item-wrapper {
    min-width: 100%;
    margin: 0 auto;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    display: flex;
    align-items: center;
    min-height: 55vh;
    padding: 0 1rem;
    @media only screen and (min-width: 576px) {
      min-height: 75vh;
      padding: 8rem 2rem;
    }
    background-position: center center !important;
    &.top-center {
      background-position: center center !important;
      @media only screen and (min-width: 960px) {
        background-position: top center !important;
      }
    }
    @media only screen and (max-width: 960px) {
      &.mobile-right-align {
        background-position: center right !important;
      }
      &.mobile-left-align {
        background-position: center left !important;
      }
    }
    &.desktop-bottom-40-left {
      background-position: bottom -40px left !important;
      @media only screen and (max-width: 960px) {
      }
    }
    &.desktop-bottom-40-right {
      background-position: bottom -40px right !important;
      @media only screen and (max-width: 960px) {
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #00000078;
      z-index: 0;
    }

    &.blue {
      &::before {
        background-color: #2511987a;
      }
    }

    &.dark-soft {
      &::before {
        background-color: #00000030;
      }
    }

    &.overlay-none {
      &::before {
        background-color: rgba(0, 0, 0, 0);
      }
    }

    &.left {
      justify-content: flex-start;

      .Hero-item-content {
        align-items: flex-start;
        text-align: left;

        @media (max-width: 576px) {
          text-align: center;
          align-items: center;
        }
      }
      &.mobile-left {
        justify-content: flex-start;
        .Hero-item-content {
          align-items: flex-start;
          text-align: left;
          @media (min-width: 576px) {
            align-items: flex-start;
            text-align: left;
          }
        }
      }
    }

    &.right {
      justify-content: flex-end;

      .Hero-item-content {
        align-items: flex-end;
        text-align: right;

        @media (max-width: 576px) {
          text-align: center;
          align-items: center;
        }
      }
      &.mobile-left {
        justify-content: flex-start;
        .Hero-item-content {
          align-items: flex-start;
          text-align: left;
          @media (min-width: 576px) {
            align-items: flex-end;
            text-align: right;
          }
        }
      }
    }

    &.center {
      justify-content: center;

      .Hero-item-content {
        text-align: center;
        align-items: center;

        .hero-header {
          letter-spacing: 11px;
        }
      }
    }
    .Hero-item-content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      z-index: 1;
      max-width: 1480px;
      margin: 0 auto;
      padding: 2rem 0;
      width: 70%;
      @media (min-width: 576px) {
        width: 85%;
      }
      &.text-shadow {
        h2,
        p {
          text-shadow: 0px 0px 2.5px rgba(0, 0, 0, 0.25);
        }
      }
      &.white {
        .hero-header {
          color: #fff;
        }

        .hero-subheader {
          color: #e0e0e0;
        }
      }
      &.black {
        .hero-header {
          color: #000;
        }

        .hero-subheader {
          color: #cacaca;
        }
      }

      .hero-header {
        margin: 0;
        padding: 0;
        font-weight: 700 !important;
        margin-bottom: 1.5rem;
        text-transform: uppercase;
        line-height: 125%;
        font-size: 21px;
        letter-spacing: 0px !important;
        @media (min-width: 320px) {
          font-size: 25px;
        }
        @media (min-width: 576px) {
          font-size: 46px;
          letter-spacing: 2px;
        }
      }

      .hero-subheader {
        margin: 0;
        padding: 0;
        margin-bottom: 2rem;
        line-height: 100%;
        font-size: 19px;
        width: 100%;
        @media (max-width: 576px) {
          font-size: 14px;
          width: 70%;
        }
      }

      .overlay-logo {
        width: 200px;
        @media (min-width: 340px) {
          width: 240px;
        }
        @media (min-width: 576px) {
          width: 320px;
        }
      }
    }
  }
}
