.p-toast.p-component.p-toast-bottom-left {
  top: 50vh !important;
  left: -150px !important;
  transition: left 200ms ease-in-out;
  width: max-content;
  height: max-content;
  @media only screen and (min-width: 768px) {
    &.active {
      left: 0px !important;
    }
  }
}

.p-toast-message.share-widget.p-toast-message-enter-done {
  background: #fff !important;
  width: 48px !important;
  box-shadow: 4px 4px 5px rgb(0 0 0 / 15%) !important;

  .p-toast-message-content {
    padding: 0;
  }

  .share-widget-label {
    font-size: 10px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0 auto;
    padding: 0.2rem 0 0 0;
  }

  .p-toast-icon-close.p-link {
    display: none;
    position: absolute !important;
    top: 0; 
    right: 0px;
    border-radius: 0 !important;
    background: none !important;
    span {
      background: none !important;
      color: red !important;
      box-shadow: 4px 4px 5px rgb(0 0 0 / 15%) !important;
    }
  }

  .p-toast .p-toast-message.p-toast-message-info {
    border-width: 0 !important;
    border: none !important;
  }
  .p-toast .p-toast-message .p-toast-message-content {
    border-width: 0 !important;
    border: none !important;
    padding: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  a, .fb-share-button  {
    height: 48px;
    width: 48px !important;
    margin: 0 auto !important;
    display: block !important;
    padding: 0 !important;
    aspect-ratio: 1;
    svg {
      height: 48px;
      width: 48px !important;
      margin: 0 auto !important;
      padding: 0 !important;
      display: block !important;
      aspect-ratio: 1;
    }
  }
}
