a {
  font-family: 'Raleway', sans-serif;
  font-size: 13.2px!important;
  text-transform: uppercase;
  letter-spacing: 2px!important;
  font-weight: 600!important;
  line-height: normal !important;
  &.button {
    &--white  {
      transition: all 200ms ease;
      font-weight: 600;
      &:hover, &:focus-within {
        color: #000 !important;
        background: #fff !important;
        border: 1px solid #fff;
        line-height: normal !important;
      }
      &:focus-visible {
        outline: 2px solid #005FCC !important;
      }
    }
    &--white--filled  {
      transition: all 200ms ease;
      font-weight: 600;
      color: #000 !important;
      background: #fff !important;
      line-height: normal !important;
      &:hover, &:focus-within {
        background: darken($color: #fff, $amount: 20) !important;
        border: 1px solid darken($color: #fff, $amount: 20) !important;
      }
      &:focus-visible {
        outline: 2px solid #005FCC !important;
      }
    }
    &--blue  {
      transition: all 200ms ease;
      font-weight: 600;
      line-height: normal !important;
      &:hover, &:focus-within {
        color: #fff !important;
        background: #7170FF !important;
        border: 1px solid #7170FF;
      }
      &:focus-visible {
        outline: 2px solid #005FCC !important;
      }
    }
    &--blue--filled  {
      transition: all 200ms ease;
      font-weight: 600;
      color: #fff !important;
      background: #7170FF !important;
      border: 1px solid #7170FF;
      line-height: normal !important;
      &:hover, &:focus-within {
        color: #fff !important;
        background: darken($color: #7170FF, $amount: 20) !important;
        border: 1px solid darken($color: #7170FF, $amount: 20) ;
      }
      &:focus-visible {
        outline: 2px solid #005FCC !important;
      }
    }
  }
  &.cta-primary {
    border-radius: 165px !important;
    letter-spacing: 0.2em !important;
    line-height: normal !important;
  }
  &.text--link {
    &--white  {
      transition: all 200ms ease;
      line-height: normal !important;
      &:hover, &:focus-within {
        color: #828282 !important;
      }
      &:focus-visible {
        outline: 2px solid #005FCC !important;
      }
    }
    &--blue  {
      transition: all 200ms ease;
      line-height: normal !important;
      &:hover, &:focus-within {
        color: #7170FF !important;
      }
      &:focus-visible {
        outline: 2px solid #005FCC !important;
      }
    }
  }
  &.letter-spacing-normal {
    letter-spacing: normal !important;
  }
}
