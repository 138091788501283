.content-page {
  h2 {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0px;
    line-height: 1.4;
    margin-bottom: 10px;
    color: var(--text-color);
  }

  p {
    text-align: justify;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.7;
    margin-bottom: 3rem;
    color: var(--text-color-secondary);
  }
  .p-toggleable-content {
   a {
    color: var(--primary-color)  !important;
    letter-spacing: initial !important;
   }
  }
  .p-accordion {
    .p-accordion-header .p-accordion-header-link, .p-accordion-header.p-highlight {
      background: var(--surface-ground) !important;
    }
    .p-accordion-content {
      background: var(--surface-ground);
    }
  }
  .react-media-player {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media only screen and (min-width: 768px) {
      &::before {
        content: '';
        position: absolute;
        top: -5px;
        left: 75px;
        height: 25px;
        width: 150px;
        background-color: #111;
        z-index: 99;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
  .react-media-player,
  .react-media-player > * {
    height: 100%;
    width: 100%;
    border-radius: 30px;
    @media only screen and (min-width: 768px) {
      height: calc(100% - 10px);
      width: calc(100% - 10px);
    }
  }
}

#faq-iframe {
  height: 1500px;
} 

#about-page {
  h2 {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0px;
    line-height: 1.4;
    margin-bottom: 10px;
    color: var(--text-color);
  }

  p {
    text-align: justify;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.7;
    margin-bottom: 3rem;
    color: var(--text-color-secondary);
  }

}
#about-page {
  h2 {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0px;
    line-height: 1.4;
    margin-bottom: 10px;
    color: var(--text-color);
  }

  p {
    text-align: justify;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.7;
    margin-bottom: 3rem;
    color: var(--text-color-secondary);
  }

}
