.collection {
  &__container {
    display: flex;
    justify-content: center;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 30vh;
    width: 100%;
    margin: 0px auto;
    background-color: var(--primary-color);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      inset: 0 0 0 0;
      background-color: #00000078;
      z-index: 1;
    }
    &__text {
      position: relative;
      z-index: 2;
      text-align: center;
      a {
        font-size: 1em;
        text-decoration: none;
        margin: 0 auto;
        display: block;
        text-transform: uppercase;
        text-align: center;
        color: var(--gray-50);
        opacity: 0.75;
        transition: opacity 200ms ease;
        &:hover,
        &:focus-within {
          opacity: 1;
        }
      }
      p {
        font-size: 1.25em;
        color: var(--gray-50);
        width: 100%;
        margin: 0 auto;
        text-align: center;
      }
    }
  }
  &__header {
    color: var(--gray-50);
    font-size: 2em;
    width: 80%;
    margin: 0 auto;
    @media screen and (min-width: 769px) {
      width: 100%;
      font-size: 3.5em;
    }
    text-align: center;
    margin-bottom: 0rem;
  }
  &__subheader {
    color: var(--text-color);
  }
  &__vendors-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  &__vendors {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: contain;
    background-color: var(--gray-50);
    border: 2px solid var(--primary-color);
    margin: 1rem auto;
    transform: scale(0.8);
    @media screen and (min-width: 989px) {
      margin: 1rem 2rem;
      transform: scale(1);
    }
    padding: 1rem;
    min-width: 6rem;
    max-width: 6rem;
    min-height: 6rem;
    aspect-ratio: 1;
    border-radius: 50%;
    transition: all 200ms ease;
    text-decoration: none;
    &:hover,
    &:focus-visible {
      border: 2px solid var(--primary-darker-color);
    }
    &:focus-visible {
      outline: 2px solid #005fcc !important;
    }
  }

  &__text {
    margin: 0;
    text-align: center;
    color: var(--primary-darker-color);
    font-size: 0.5rem;
    // // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // max-width: 200px;
  }
  h4 &__text {
    margin: 0.15rem;
  }
}
