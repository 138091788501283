.error--page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  padding: 2rem;
  background: linear-gradient(90deg, rgba(3, 0, 124, 0.56) 0%, rgba(95, 95, 95, 0.35) 100%) black;
  img {
    align-self: flex-start;
  }
  .error--page--content {
    margin: auto 0;
    text-align: center;
    color: white;
    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 112px;
      line-height: 37px;
      margin-bottom: 2.5rem;
    }
    h2 {
      font-style: normal;
      font-weight: normal;
      font-size: 42px;
      line-height: 37px;
      margin-bottom: 3rem;
    }
    p {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 3rem;
    }
  }
}