.digital-content__breadcrumb {
  a.p-menuitem-link {
    color: #027f7d !important;
  }
  .p-breadcrumb-chevron {
    color: #027f7d !important;
    &:first-of-type {
      display: none;
    }
  }
}

.digital-content__list {
  .p-orderlist-list {
    min-height: unset;
    max-height: unset;
  }
  .p-orderlist-controls {
    display: none;
  }
  .p-orderlist-item {
    cursor: default;
  }
}

.digital-content__item {
  margin: 0 !important;
}

.react-media-player__dc,
.react-media-player__dc > * {
  height: 100%;
  width: 100%;
}

.p-dialog-mask.p-component-overlay {
  z-index: 1000;
}

.p-dialog-content {
  position: relative;
}

.share-card {
  position: absolute;
  overflow: hidden;
  right: 24px;
  top: 0;
  width: 80%;
  @media screen and (min-width: 640px) {
    width: 60%;
  }
  @media screen and (min-width: 1200px) {
    width: 30%;
  }
  height: 0px;
  z-index: 1500;
  background: rgb(24, 24, 24);
  transition: height 300ms ease-in-out;
  border-radius: 0 0 0 8px;
  *,
  input {
    opacity: 0;
    transition-property: opacity;
    transition-delay: 300ms;
    visibility: hidden;
  }
  &.active {
    position: absolute;
    height: 95.2%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    label {
      color: white;
    }
    *,
    input {
      opacity: 1;
      visibility: unset;
    }
    .share-card-cta {
      font-size: 18px;
      color: white;
      font-weight: bold;
    }
    .share-card-submit {
      text-decoration: none;
      color: #fff;
      background: #027f7d;
      padding: 8px 16px;
      width: 100%;
      border-radius: 4px;
      text-align: center;
    }
    .share-card-close {
      position: absolute;
      left: 0px;
      bottom: 0px;
      border: none;
      outline: none;
      width: 100%;
      background: none;
      padding: 24px 16px;
      z-index: 1502;
      color: white;
      cursor: pointer;
      font-weight: bold;
      opacity: 0.75;
      transition: opacity 200ms ease;
      background: rgb(6, 6, 6);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      &:hover {
        opacity: 1;
      }
    }
  }
}

.parsedHTML {
  color: var(--text-color);
  ul {
    margin-left: 0;
    padding-left: 0;
    list-style-position: inside;
    list-style: none;
    li {
      margin-left: 0;
      p {
        margin: 0.5rem 0;
        &.uppercase {
          margin: 0;
        }
      }
    }
  }
  p {
    margin-bottom: 0.5rem !important;
    &:last-of-type {
      margin-bottom: 2rem !important;
    }
  }
}

.digital-content-video-dialog .video-iframe-container iframe, .video-iframe-container object, .video-iframe-container embed { 
  @media screen and (min-width: 1200px) {
  height: 73%;
  }
}