@import '../../pages/shop-home-page/shop-scss/shop-index.scss';

.blog-item {
  position: relative;
  background: var(--surface-ground) !important;
  border: 0.5px solid var(--surface-500);
  padding: 10px 6px !important;
  margin: 0 auto;
  .blog-img-wrapper {
    width: 100%;
    padding-top: 55%;
    max-height: 200px;
    position: relative;
    overflow: hidden;
    display: block;
    background-size: contain !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;

    // img {
    //   backface-visibility: hidden;
    //   position: absolute;
    //   top: 0;
    //   width: 100%;
    //   min-height: 100%;
    //   border: none;
    //   transition: transform 200ms ease-out;
    //   object-fit: cover;
    // }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      transition: background 200ms ease-out;
      z-index: 0;
    }

    &:hover {
      img {
        transform: scale(1.1, 1.1);
      }

      &::after {
        background: #ffffff50;
      }
    }

    .blog-date {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: var(--primary-color);
      height: 50px;
      min-width: 50px;
      aspect-ratio: 1;
      inset: 0 auto 0 auto;
      padding: 0;
      margin: 0;

      span {
        color: var(--primary-color-text);
        font-family: 'Montserrat', sans-serif;
        text-transform: uppercase;
        font-weight: bold;
        display: block;

        &:first-of-type {
          font-size: 18px;
          font-weight: bolder;
        }
        &:last-of-type {
          font-size: 10px;
        }
      }
    }
  }
  .blog-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px !important;
    letter-spacing: initial !important;
    font-weight: bold !important;
    margin-top: 30px;
    margin-bottom: 6px;
    line-height: 1.5;
    text-transform: capitalize;
    text-decoration: none;
    color: var(--text-color);
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    &:hover,
    &:focus-within {
      color: var(--primary-color);
    }
  }

  .blog-text {
    font-size: 14px;
    color: var(--text-color-secondary);
    font-weight: normal;
    line-height: 26px;
    margin: 0 0 10px;

    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .blog-readmore {
    position: absolute;
    bottom: 1.3rem;
    font-family: 'Montserrat', sans-serif;
    font-size: 10px !important;
    text-transform: uppercase;
    letter-spacing: 0.1em !important;
    color: var(--text-color);
    text-decoration: none;
    transition: color 200ms ease;
    &:hover,
    &:focus-within {
      color: var(--primary-color);
    }
  }
  .content {
    min-height: 9rem;
    max-height: 9rem;
    padding: 0px 10px 20px;
  }
}

.blog-slider {
  .blog-img-wrapper {
    background-size: cover !important;
  }
}