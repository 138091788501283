.p-component.visit-website-btn {
  background: var(--surface-ground) !important;
  border: 0.5px solid var(--surface-500);
  padding: 10px 6px !important;
  &:hover, &:focus-within {
    border: 0.5px solid var(--primary-color) !important;
  }
  img {
    aspect-ratio: 1;
    height: 205px;
    @media only screen and (min-width: 320px) {   
      height: 305px;
    }
    @media only screen and (min-width: 961px) {   
      height: 345px;
    }
  }
  p.visit-website-header {
    font-weight: bold !important;
    padding: 0.25rem 0 0.5rem !important;
    font-size: 18px!important;
    line-height: 24px!important;
    @media only screen and (min-width: 576px) {
      font-size: 21px!important;
      line-height: 27.3px!important;
    }
    margin: 0;
    text-transform: uppercase;
    text-align: left;
  }
  p.visit-website-link {
    padding: 0.25rem 0 0 !important;
    margin: 0;
    color: var(--primary-color);
    font-size: 13px!important;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    svg {
      font-size: 0.5rem;
    }
  }
}

.visit-website-has-description {
  a.learn-more-button, button.learn-more-button {
    border: none !important;
    background-color: var(--primary-color) !important; 
    color: var(--primary-color-text) !important;
    font-weight: 700 !important;
    transition: 200ms ease color;
    text-decoration: none;
    padding: 0.5rem 1rem;
    font-family: 'Raleway', sans-serif !important;
    display: flex;
    align-items: center;
    letter-spacing: 2px;
    svg {
      transform: scale(0.75);
    }
    &:hover {
      background: none !important;
      border: none !important;
      color: var(--primary-color) !important;
    }
  }
}