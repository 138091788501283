@import '../shop-scss/shop-index.scss';

#productsByTags {
  padding: 70px 0;

  .product-widget-list {
    .product-widget {
      position: relative;
      display: flex;
      background: transparent !important;
      border: none;
      overflow: hidden;
      padding: 20px 0 !important;
      border-bottom: 1px solid var(--surface-card);
      text-decoration: none;
      color: var(--text-color);

      &:last-child {
        border-bottom: none;
      }

      img {
        width: 54px;
        @media only screen and (min-width: 341px) {
          width: 64px;
        }
        @media only screen and (min-width: 767px){
          width: 84px;
        }
        margin-right: 17px;
        object-fit: cover;
      }
      .content {
        text-align: left !important;
        .product-title {
          margin-top: 0;
          margin-bottom: 5px;
          line-height: 14px;
          font-family: 'Raleway', sans-serif;
          text-transform: uppercase;
          font-weight: normal;
          letter-spacing: 0.1em;
          font-size: 13px;
        }

        .price {
          font-size: unset !important;
          font-family: 'Montserrat', sans-serif;
          font-weight: 500;

          del {
            color: $dark-text;
            margin-right: 10px;

            span {
              font-size: 14px;
            }
          }
          ins {
            text-decoration: none;
            color: var(--text-color);

            .amount {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
