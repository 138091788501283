#contact-us {
  padding: 70px 0;

  .header-section {
    h2 {
      margin-bottom: 1px;
      font-size: 20px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 0.3em;
      text-align: center;
      color: var(--text-color);
    }

    p {
      font-size: 15px;
      font-weight: 300;
      color: var(--text-color-secondary);

      a {
        color: inherit;
      }
    }
  }

  .contact-item {
    position: relative;
    transition: all 0.27s cubic-bezier(0.3, 0.1, 0.58, 1);
    padding-left: 38px;
    @media screen and (min-width: 960px) {
      padding-left: 44px;
    }

    svg {
      position: absolute;
      top: 8px;
      left: 7px;
      color: var(--text-color);
      text-align: center;
      font-size: 18px;
      @media screen and (min-width: 960px) {
        font-size: 22px;
      }
    }

    .title {
      margin-bottom: 1px;
      font-size: 12px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 0.3em;
      font-family: 'Montserrat', sans-serif;
      color: var(--text-color);
    }

    .text {
      font-size: 13px;
      color: #555;
      @media screen and (min-width: 960px) {
        font-size: 15px;
      }
      font-weight: 300;
      color: var(--text-color-secondary);
    }
    .contact-us__mailto:hover {
      color: var(--surface-600);
    }
  }

  .contactForm {
    display: flex;
    flex-flow: column wrap;
    align-content: space-between;
    height: 240px;

    &:before,
    &:after {
      content: '';
      flex-basis: 100%;
      width: 0;
      order: 2;
    }

    .input {
      margin-right: 0 !important;
    }

    textarea {
      &.hs-input {
        height: 140px;
      }
    }

    .hs_firstname,
    .hs_lastname,
    .hs_email {
      width: 49.4%;
      margin-right: 1.2%;
      margin-bottom: 0;
    }

    .hs_message,
    .hs_submit {
      width: 49.4%;
    }

    .hs-input {
      height: 37px;
      font-size: 12px;
      -webkit-border-radius: 2px !important;
      -moz-border-radius: 2px !important;
      border-radius: 2px !important;
      width: 100% !important;
      padding: 0.375rem 13px;
      margin-bottom: 7px;
      margin-top: 8px;
      line-height: 1.5;
      background-color: var(--surface-card);
      display: inline-block;
      vertical-align: middle;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: var(--text-color);
      border: 1px solid var(--surface-border);
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
      -moz-transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
      -o-transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
      -ms-transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
      transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);

      &:focus {
        border-color: var(--surface-overlay);
        -webkit-box-shadow: 0 0 3px var(--surface-hover);
        -moz-box-shadow: 0 0 3px var(--surface-hover);
        box-shadow: 0 0 3px var(--surface-hover);
        // outline: none;
      }

      &.error {
        border-color: #e33131;
        color: #e33131;
      }
    }

    textarea {
      .hs-input {
        height: 131px;
      }
    }

    label {
      margin-bottom: 0.5rem;
    }

    label:not(.hs-error-msg, .hs-form-booleancheckbox-display) {
      display: none;
    }

    .actions {
      text-align: right;
    }

    .hs-button {
      height: auto;
      padding: 8px 20px;
      font-size: 12px;
      color: var(--text-color);
      background: var(--surface-300);
      border: 1px solid transparent;
      font-weight: 400;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 2px;
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      user-select: none;
      line-height: 1.5;
      cursor: pointer;

      &:hover {
        background: var(--surface-400);
        color: var(--primary-color-text);
      }
    }

    .hs-error-msgs {
      margin: 0;
      font-size: 12px;
      padding-left: 15px;
      color: #e33131;
      line-height: normal;
      list-style: none;
    }
  }

  .submitted-message {
    p {
      color: var(--text-color);
      font-size: 18px;
      font-weight: 600;
      margin: 0;
      text-align: center;
    }
  }

  @media (max-width: 576px) {
    padding: 0px 0;

    .contactForm {
      height: auto;

      .hs_firstname,
      .hs_lastname,
      .hs_email,
      .hs_message,
      .hs_submit {
        width: 100%;
      }
    }

    .header-section {
      h2 {
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.1em;
      }

      p {
        font-size: 14px;
        font-weight: 300;
      }
    }
  }

  .contact-us__link-join-our-cause:hover {
    color: var(--primary-color);
  }
}
