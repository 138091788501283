.top-header {
    overflow: hidden;
    background-size: cover !important;
    position: relative;
    width: 100%;
    min-height: 100%;
    padding: 24px 2rem;
    width: 100%;
    background-repeat: no-repeat !important;
    @media only screen and (min-width: 768px) {
      height: 100vh;
      max-height: 100vh;
      padding: 0 122px;
    }
    @media only screen and (max-width: 576px) {
      padding-top: 34px;
    }
    &:after {
      content:'';
      position: absolute;
      overflow: hidden;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      background:linear-gradient(90deg, rgba(4, 0, 196, 0.42) 0%, rgba(95, 95, 95, 0.62) 100%);
    }  
    &:after {
      background:linear-gradient(90deg, rgba(3, 0, 156, 0.42) 0%, rgba(76, 76, 76, 0.62) 100%);
    }
    &.theduttons {
      background:linear-gradient(90deg, rgba(3, 0, 156, 0.42) 0%, rgba(76, 76, 76, 0.62) 100%);
      @media only screen and (max-width: 768px) {
        background-position: top center;
      }
    }
    &.drbuttar {
      background-size: cover;
      background-position: -340px center;
      @media only screen and (min-width: 576px) {
        background-position: -160px center;
      }
      @media only screen and (min-width: 768px) {
        background-position: -80px center;
      }
      @media only screen and (min-width: 969px) {
        background-size: 300% !important;
        background-position: -40px center;
      }
      @media only screen and (min-width: 1440px) {
        background-size: 200% !important;
        background-position: left center;
      }
    }
    &.eileenmckusick {
      @media only screen and (max-width: 768px) {
        background-position: -530px center;
      }
    }
    &.loislaynee {
      @media only screen and (max-width: 1000px) {
        background-position: -250px center;
      }
      @media only screen and (max-width: 768px) {
        background-position: -350px center;
      }
      background-position: left center;
    }
    &.tap {
      @media only screen and (min-width: 969px) {
        background-position: -100px bottom;
        background-size: 200% !important;
      }
      @media only screen and (min-width: 1200px) {
        background-size: 150% !important;
      }
    }
    &.academyofselfknowledge {
      @media only screen and (max-width: 576px) {
        background-position: center;
      }
      @media only screen and (min-width: 969px) {
        background-position: -100px bottom;
        background-size: 200% !important;
      }
      @media only screen and (min-width: 1200px) {
        background-size: 150% !important;
      }
    }
    &.deterioration {
      background-position: center center;
    }
    &.voicesagainsttrafficking {
      @media only screen and (max-width: 768px) {
        background-position: -750px center;
      }
    }
    &.wildeinfluence {
      background-position: top right -500px;
      @media only screen and (max-width: 576px) {
        background-position: -1500px center;
      }
    }
    &.dontclick {
      @media only screen and (max-width: 576px) {
        background-position: -600px center;
      }
    }
    &.vitalelements:after  {
      background:linear-gradient(90deg, rgba(2, 0, 125, 0) 0%, rgba(05, 05, 05, 0.8) 100%);
    }
  &__gradient {
    h1 {
      margin-bottom: 2.5rem;
    }
  }
  p.text,
  div.text {
    @media only screen and (min-width: 768px) {
      max-width: 700px;
    }
    &.text-lg {
      font-size: 1.15rem !important;
      line-height: 2rem !important;
    }
  }
  .main-heading-title {
    @media only screen and (max-width: 500px) {
      font-size: 2.5rem!important;
    }
  }

}