@font-face {
  font-family: 'TradeGothic';
  src: local('TradeGothic'),
    url('./fonts/Trade-Gothic/Trade-Gothic-LT-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'TradeGothicLight';
  src: local('TradeGothicLight'),
    url('./fonts/Trade-Gothic/Trade-Gothic-LT-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'TradeGothicOblique';
  src: local('TradeGothicOblique'),
    url('./fonts/Trade-Gothic/Trade-Gothic-LT-Oblique.ttf') format('truetype');
}

@font-face {
  font-family: 'TradeGothicBoldOblique';
  src: local('TradeGothicBoldOblique'),
    url('./fonts/Trade-Gothic/Trade-Gothic-LT-Bold-Oblique.ttf')
      format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'TradeGothicCondensedOblique';
  src: local('TradeGothicCondensedOblique'),
    url('./fonts/Trade-Gothic/Trade-Gothic-Condensed-Bold.ttf')
      format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'TradeGothicNo20CondensedBold';
  src: local('TradeGothicCondensedOblique'),
    url('./fonts/Trade-Gothic/TradeGothicNo20-Condensed-Bold.ttf')
      format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Orbitron';
  src: local('Orbitron'),
    url('./fonts/Orbitron/Orbitron-Regular.ttf') format('truetype');
  font-weight: regular;
}

html,
body,
#root {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  // overflow-y: scroll;
  // margin-left: calc(100vw - 100%);
}

*,
*:before,
*:after {
  box-sizing: inherit;
  // To remove this property on all primereact components by default
  // box-shadow: none !important;
}

body {
  font-family: Raleway, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  font-size: 14px;
  color: var(--text-color);
  font-weight: normal;
  margin: 0;
  line-height: 26px;
  overflow-x: hidden !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--surface-ground);
  scrollbar-gutter: stable both-edges;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--surface-900);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover,
  &::-webkit-scrollbar-thumb:active {
    background-color: var(--surface-900);
  }

  &::-webkit-scrollbar-track {
    background-color: var(--surface-500);
    border-radius: 4px;
  }
  
  &.checkout, &.checkout-complete, &.orders, &.digital-content, .cart-summary-sidebar, .login-account-form , &.exchange-sitemap, &.cart {
    background: var(--surface-invert);
    .p-inputtext, .p-dropdown {
      background: var(--surface-invert);
    }
    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
      background: transparent;
    }
    .p-tabview .p-tabview-panels {
      background: var(--surface-invert);
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p.title,
    p.visit-website-header,
    a,
    p,
    a span.p-menuitem-text {
      font-family: 'Montserrat', sans-serif !important;
      font-weight: 900 !important;
    }
    h2 {
      font-size: 1.5rem !important;
      line-height: 1.1em;
      font-weight: 900 !important;
    }
    @media only screen and (min-width: 960px) {
      h2 {
        font-size: 1.5rem !important;
      }
    }
    h3 {
      font-size: 1.75rem !important;
      line-height: 1.1em;
      font-family: 'Oswald', Helvetica, Arial, Lucida, sans-serif;
      font-weight: 700;
    }
    p {
      font-size: 1.3rem;
      font-weight: 400 !important;
    }
    input,
    textarea,
    select,
    div,
    button {
      font-family: 'Montserrat', sans-serif !important;
      font-weight: 400 !important;
    }
  }
}

.var-surface-invert {
  background-color: var(--surface-invert);
  &.p-dialog.p-component {
     .p-dialog-header, .p-dialog-content {
      background-color: var(--surface-invert) !important;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  margin-top: 0;
  font-weight: 700 !important;
  line-height: 1.5;
}

.montserrat {
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
}

.raleway-price-format {
  font-feature-settings: 'lnum' 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.raleway-price-format {
  font-feature-settings: 'lnum' 1;
}

.p-dropdown.p-component span.p-dropdown-label.p-inputtext {
  line-height: 16px !important;
  padding: 0.5rem 0.75rem;
}

// Global Styles to rewrite some primereact component styles

.c-button-gray {
  .p-button,
  &.p-button,
  .p-orderlist-controls > .p-button,
  .p-inputnumber-button.p-inputnumber-button-up,
  .p-inputnumber-button.p-inputnumber-button-down {
    background-color: var(--gray-600) !important;
    border-color: var(--gray-600) !important;
    color: #fff;

    &:hover,
    &:focus-visible {
      background-color: var(--gray-800) !important;
      border-color: var(--gray-600) !important;
    }

    .p-button-icon::before {
      color: #fff !important;
    }
  }
}

.c-button-black {
  .p-button,
  &.p-button,
  .p-orderlist-controls > .p-button {
    background-color: var(--gray-800) !important;
    border-color: var(--gray-800) !important;
    color: #fff;

    &:hover {
      background-color: var(--gray-600) !important;
      border-color: var(--gray-600) !important;
    }

    &:focus-visible {
      outline: 2px solid #005fcc !important;
    }

    .p-button-icon::before {
      color: #fff !important;
    }
  }
}

.c-button-primary {
  .p-button,
  &.p-button,
  .p-orderlist-controls > .p-button {
    background-color: var(--primary-color);
    color: var(--primary-color-text);
    border: none;
    position: relative;

    span {
      z-index: 2;
    }

    &:before {
      position: absolute;
      inset: 0 0 0 0;
      background: rgba(var(--surface-800), 0);
      z-index: 1;
      content: '';
      transition: all 200ms ease;
    }

    &:hover {
      color: var(--primary-color);
      background-color: var(--primary-color-text);

      &:before {
        background: rgba(var(--surface-800), 0.25);
      }
    }

    .p-button-icon::before {
      color: var(--text-color) !important;
    }

    &:focus-visible {
      outline: 2px solid var(--primary-color-text) !important;
    }
  }
}

.p-fluid > .c-button-primary {
  &.p-button span {
    align-self: baseline;
    margin-right: 0.5rem;
  }

  .p-button,
  &.p-button,
  .p-orderlist-controls > .p-button {
    .p-button-icon::before {
      color: var(--primary-color-text) !important;
      font-size: 1.2rem;
    }
  }
}

// .c-orderlist-gray {
//   .p-orderlist-item.p-highlight {
//     background: #e9ecef !important;
//   }
//   .p-orderlist-item.p-highlight:hover,
//   .p-orderlist-item.p-highlight:focus {
//     background: #e9ecef !important;
//   }
// }

// .p-dropdown,
// .p-inputtext {
//   &:hover,
//   &:active,
//   &:focus {
//     outline: #bbb !important;
//     border-color: #bbb !important;
//   }
// }

.p-message-error {
  .p-message-detail {
    margin-left: 5px;
  }

  .pi-times-circle {
    &:before {
      content: '\e989';
    }
  }
}

.rating {
  .fa-star,
  .fa-star-half-alt {
    color: #f0b232;
  }
}

// .p-paginator {
//   .p-paginator-pages .p-paginator-page.p-highlight {
//     background: rgba(255, 255, 255, 0.5) !important;
//     border: 1px solid;
//     border-color: var(--c-primary-color) !important;
//     color: #495057;
//   }
//   button {
//     &:focus-visible {
//       outline: 2px solid #005fcc !important;
//     }
//   }
// }

// a,
// .pi-times,
// .field-radiobutton,
// .p-tabview-nav-link {
//   &:focus-visible {
//     outline: 2px solid #005fcc !important;
//   }
// }

// .p-autocomplete-panel.p-component.p-connected-overlay-enter-done {
//   box-shadow: 4px 4px 5px rgb(0 0 0 / 15%) !important;
//   z-index: 1400 !important;
// }

// .p-autocomplete-panel .p-autocomplete-items {
//   background: #eaecef;
// }

.p-button.p-component.p-autocomplete-dropdown.p-button-icon-only {
  background: var(--primary-dark-color) !important;
  border-color: var(--primary-color) !important;
}

.category-tree {
  width: calc(100% - 14px);
  -webkit-transition: font-size, 0.5s ease;
  -moz-transition: font-size 0.5s ease;
  -o-transition: font-size 0.5s ease;
  transition: font-size 0.5s ease;

  .p-treetable .p-treetable-tbody > tr {
    line-height: 16px;

    td {
      padding: 0.2rem 1rem !important;
      border: none;
    }
  }

  .p-treetable .p-treetable-thead > tr > th,
  .p-treetable .p-treetable-tbody > tr {
    font-weight: 400 !important;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    color: var(--text-color-secondary);
    background-color: rgba(var(--surface-800), 0.25);
    padding: 0.5rem 1rem;
  }

  .p-treetable .p-treetable-thead > tr > th {
    border: none;
    color: var(--text-color);
    padding: 0px 24px;
    position: relative;

    &:before {
      position: absolute;
      margin-bottom: -12px;
      background: linear-gradient(
        to right,
        var(--primary-color) 50px,
        var(--surface-border) 50px
      );
      height: 3px;
      content: '';
      bottom: 0;
      right: 0;
      left: 1.6rem;
    }
  }

  .p-treetable .p-treetable-tbody::before {
    content: '';
    display: block;
    height: 30px;
  }

  // line heigh 18px

  .p-treetable-tbody > tr > td {
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;

    button.p-treetable-toggler.p-link.p-unselectable-text {
      margin-right: 0 !important;
    }

    i {
      font-size: 0.75rem;
    }
  }

  &__categories {
    display: inline-block;
    overflow: hidden;
    max-width: 80%;
    text-overflow: ellipsis;

    &:hover,
    &:focus,
    &:active {
      color: var(--text-color);
      font-weight: 600;
    }
  }

  .p-treetable .p-treetable-tbody > tr.p-highlight,
  .p-treetable
    .p-treetable-tbody
    > tr.p-highlight:focus
    .p-treetable.p-treetable-hoverable-rows
    .p-treetable-tbody
    > tr:not(.p-highlight):hover,
  .p-treetable.p-treetable-hoverable-rows
    .p-treetable-tbody
    > tr:not(.p-highlight):active,
  .p-treetable.p-treetable-hoverable-rows
    .p-treetable-tbody
    > tr:not(.p-highlight):focus {
    background-color: rgba(var(--surface-800), 0.25);
    color: var(--primary-color);
    font-weight: 600 !important;
    outline-color: var(--primary-color) !important;
  }
}

.sidebar-filter > .p-sidebar-content > div > aside {
  table {
    margin-bottom: 1rem;
  }

  .p-treetable .p-treetable-thead > tr > th,
  .p-treetable .p-treetable-tbody > tr {
    background: var(--surface-card) !important;
  }
}

.section-wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.container {
  width: 100%;
  max-width: 1480px;
  margin: 0 auto;
}

.section_header {
  position: relative;
  font-size: 16px;
  letter-spacing: 0.2em;
  margin-bottom: 30px;
  text-transform: uppercase;
  color: var(--text-color);

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-bottom: 3px solid var(--surface-border);
  }

  &:after {
    content: '';
    display: block;
    width: 50px;
    border-bottom: 3px solid var(--primary-color);
    margin: 19px auto 24px 0;
    z-index: 1;
    position: relative;
  }
}

.user-select {
  user-select: all;
}

.p-selectbutton .p-button.p-highlight {
  background-color: var(--c-primary-color) !important;
  border-color: var(--c-primary-color) !important;

  &:hover {
    background-color: var(--c-primary-dark-color) !important;
    border-color: var(--c-primary-dark-color) !important;
    color: var(--primary-color) !important;
  }
}

.p-treeselect {
  background: var(--surface-card);
  border: 1px solid var(--surface-border);

  &:not(.p-disabled):hover {
    border-color: var(--primary-color);
  }

  .p-treeselect-label {
    color: var(--text-color);
  }

  .p-treeselect-trigger {
    color: var(--text-color);
  }
}

.p-treeselect-panel {
  background: var(--surface-card);
  color: var(--text-color);

  .p-tree {
    border: 1px solid var(--surface-border);
    background: var(--surface-card);
    color: var(--text-color);

    .p-treenode-content {
      &:not(.p-highlight):hover {
        background: var(--surface-hover) !important;
      }

      &.p-highlight {
        background: var(--surface-hover) !important;
        color: var(--text-color) !important;
      }
    }
  }

  .p-treeselect-header {
    color: var(--text-color);
    background: var(--surface-section);
  }

  .p-checkbox .p-checkbox-box {
    border: 2px solid var(--surface-border);
    background: transparent;
    width: 20px;
    min-width: 20px;
  }

  .p-inputtext {
    color: var(--text-color);
    background: transparent;
    padding: 0.5rem 0.5rem;
    border: 1px solid var(--surface-border);
  }
}

.p-breadcrumb-chevron--hidden li.pi-chevron-right {
  display: none !important;
}

.filter-card {
  border: 0.5px solid var(--surface-500);

  .p-multiselect,
  .clear-filter {
    width: 100%;
  }
}

.mobile-sidemenu {
  .shop-categories {
    border: 0.5px solid var(--surface-500);
    overflow: hidden;
    padding-bottom: 1rem !important;

    .p-submenu-header {
      position: relative;
      padding: 14px;
      padding-bottom: 0px;
      font-family: 'Raleway', sans-serif;
      font-size: 13px;
      text-transform: uppercase;
      letter-spacing: 3px;
      font-weight: 400 !important;
      color: var(--text-color);
      margin-bottom: 1rem;
      background: var(--surface-ground) !important;

      &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        border-bottom: 3px solid var(--surface-border);
        margin-left: -14px;
      }

      &:after {
        content: '';
        display: block;
        width: 35px;
        border-bottom: 3px solid var(--primary-color);
        margin: 12px 0 0;
        z-index: 1;
        position: relative;
      }
    }

    .p-menuitem-link {
      padding: 0.5rem 1rem;

      &:hover {
        background-color: transparent !important;

        .p-menuitem-text {
          color: var(--text-color-secondary);
        }
      }

      .p-menuitem-text {
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 1.2px;
        color: var(--text-color-secondary);
        font-weight: 400;
      }
    }
  }
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--c-primary-color) !important;
  background: var(--c-primary-color) !important;
}

body #hubspot-messages-iframe-container.widget-align-left {
  bottom: 60px !important;
  z-index: 999 !important;

  @media only screen and (min-width: 961px) {
    bottom: 0px !important;
  }
}

.crwd-font {
  font-family: neuropol-x-light, sans-serif;
  font-weight: 300;
  font-style: normal;
}

.crwd-font-bold {
  font-family: neuropol-x-light, sans-serif;
  font-weight: 700;
  font-style: normal;
}

.p-badge {
  background: var(--primary-color) !important;
  color: var(--primary-color-text) !important;
}

.sidebar-h-full.p-sidebar-right {
  .p-sidebar-content {
    display: flex !important;
    height: 100% !important;
  }
}

.p-progressbar {
  height: 0.5rem !important;

  .p-progressbar-value {
    background: var(--primary-color) !important;
  }
}

.background-image-square {
  height: 100px;
  width: 100px;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  display: block;
}

.thin-border-card {
  background: var(--surface-ground) !important;
  border: 0.5px solid var(--surface-500);
  padding: 10px 6px !important;

  .p-multiselect {
    background: var(--surface-ground) !important;
  }
}

.p-paginator {
  background: var(--surface-ground) !important;
}

.p-dropdown {
  .p-dropdown-trigger {
    color: var(--surface-900) !important;
    background-color: transparent !important;
  }
}

.p-button.p-component.p-autocomplete-dropdown.p-button-icon-only {
  color: var(--surface-900) !important;
  background-color: var(--surface-invert) !important;
  border: 1px #ced4da solid !important;
  max-height: 2.5rem;
}

.p-inputtext.p-component.p-autocomplete-input {
  border: 1px solid #ced4da;
}

.orbitron {
  font-family: Orbitron !important;
}

.react-tel-input {
  border-radius: 3px;
  height: auto !important;
  max-height: 2.5rem;

  .form-control {
    border-color: #ced4da !important;
    border-radius: 3px !important;
  }

  .flag-dropdown {
    top: 1px;
    bottom: 0px;
    left: 1.5px;
    max-height: 2.4rem;
    background-color: transparent !important;
  }

  .flag-dropdown.invalid-number {
    border-color: transparent !important;
  }
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  color: var(--surface-ground) !important;
  background: var(--primary-color) !important;
}

.checkout {
  position: relative;
}

.checkout::after {
  content: '';
  height: 95%;
  width: 0.2px;
  position: absolute;
  right: 0;
  top: 4%;
  background-color: var(--primary-color);
  opacity: 0.2;
}

.p-sidebar-bottom .p-sidebar {
  min-height: 90vh;
}

.p-sidebar-mask.p-component-overlay {
  background: rgba(0, 0, 0, 0.6) !important;
}

.image-object-fit-cover img {
  object-fit: cover;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.admin-phone-input {
  .p-dropdown-label.p-inputtext {
    text-overflow: unset !important;
  }
}

.p-sidebar-bottom .p-sidebar {
  background: var(--surface-invert);
}