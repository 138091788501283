.shop-button {
  transform: all 200ms ease;
  text-align: center;
  &.solid {
    font-family: 'Raleway', sans-serif;
    display: inline-block;
    padding: 6px 28px;
    font-size: 11px;
    @media only screen and (min-width: 576px)  {
      font-size: 13px;
      padding: 13px 34px;
    }
    text-decoration: none;
    letter-spacing: 0.14em;
    font-weight: bold;
    text-transform: uppercase;

    color: #000;
    border: 2px solid #fff;
    background-color: #fff;

    &.sb-primary {
      background-color: var(--primary-color);
      color: var(--primary-color-text);
      &:hover {
        color: var(--primary-color) !important;
        background-color: var(--primary-color-text) !important;
      }
    }
    &.black {
      color: #fff !important;
      background: #000 !important;
      border-color: #000;
      &:hover {
        background: #fff !important;
        color: #000 !important;
        border-color: #000 !important;
      }
    }

    &:hover {
      background-color: var(--primary-color) !important;
      border-color: var(--primary-color);
      color: var(--primary-color-text) !important;
    }
    &:focus-visible {
      outline: 2px solid #005fcc;
    }
  }

  &.outline {
    display: inline-block;
    font-size: 13px;
    padding: 13px 34px;
    color: #fff;
    border: 2px solid #fff;
    text-decoration: none;
    letter-spacing: 0.14em;
    font-weight: bold;
    text-transform: uppercase;
    background-color: transparent !important;

    &.black {
      color: #000 !important;
      border-color: #000;
    }

    &.white {
      color: #fff !important;
      border-color: #fff;
      &:hover {
        background-color: var(--primary-color) !important;
        color: var(--primary-color-text) !important;
      }
    }
    &.green {
      color: var(--primary-color);
      background-color: #fff !important;
      border-color: var(--primary-color);
      &:hover {
        background-color: var(--primary-dark-color) !important;
        border-color: var(--primary-dark-color) !important;
      }
    }
    &:hover {
      color: var(--primary-color-text);
      background-color: var(--primary-color) !important;
      border-color: var(--primary-color) !important;
    }
  }

  &.hero {
    &.white {
      color: #000;
      background-color: #fff;
      &:hover {
        background-color: var(--primary-color) !important;
        color: var(--primary-color-text);
        border: 2px solid var(--primary-darker-color);
      }
    }
    &.black {
      color: #fff;
      background-color: #000;
    }
  }
 &.text-button {
  background: none;
  border-color: transparent;
  color: var(--primary-color);
  text-transform: uppercase;
  letter-spacing: 0.14em;
  font-weight: bold;
  font-size: 13px;
  font-family: 'Raleway', sans-serif;
  &:hover, &:focus-within {
    color: var(--primary-color) !important;
    background-color: transparent !important;
    border-color:  var(--primary-color) !important;
  }
  &:focus-visible {
    outline: 2px solid #005fcc;
  }
 } 
}
