.categories {
  #search-product {
    > input {
      width: 100%;
    }
  }
  .pi-search,
  .pi-times {
    color: #888;
  }
  .p-autocomplete-input {
    padding-left: 2rem;
  }

  .p-dataview-content > .p-grid {
    display: flex;
    flex-wrap: wrap;
  }
  .p-card-body {
    flex: 1;
  }
  .p-card-content {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 767px) {
      justify-content: space-between;
    }
    height: 100%;
    padding: 0 !important;
  }
  .cat-aside {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }
  .shop-categories {
    border: 0.5px solid var(--surface-500);
    overflow: hidden;
    padding-bottom: 1rem !important;

    .p-submenu-header {
      position: relative;
      padding: 14px;
      padding-bottom: 0px;
      font-family: 'Raleway', sans-serif;
      font-size: 13px;
      text-transform: uppercase;
      letter-spacing: 3px;
      font-weight: 400 !important;
      color: var(--text-color);
      margin-bottom: 1rem;
      background: var(--surface-ground) !important;

      &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        border-bottom: 3px solid var(--surface-border);
        margin-left: -14px;
      }

      &:after {
        content: '';
        display: block;
        width: 35px;
        border-bottom: 3px solid var(--primary-color);
        margin: 12px 0 0;
        z-index: 1;
        position: relative;
      }
    }

    .p-menuitem-link {
      padding: 0.5rem 1rem;
      &:hover {
        background-color: transparent !important;
        .p-menuitem-text {
          color: var(--text-color-secondary);
        }
      }
      &:focus-visible {
        outline: 2px solid #005fcc !important;
      }
      .p-menuitem-icon {
        display: none;
      }
      .p-menuitem-text {
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 1.2px;
        color: var(--text-color-secondary);
        font-weight: 400;
      }
    }
  }

  .sidemenu-header {
    text-transform: uppercase;
    font-weight: bold !important;
    color: var(--text-color);
    letter-spacing: 1px;
    padding: 0.5rem 0;
    margin-bottom: 3rem;
    position: relative;
    font-size: 1rem;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0px;
      background: var(--primary-color);
      width: 25%;
      height: 2px;
    }
  }

  .range-inputgroup {
    span {
      padding: 0rem 0.3rem;
      min-width: auto;
    }
  }

  .category-page__breadcrumb {
    background: transparent !important;
    border: 1px solid transparent !important;

    .p-breadcrumb-chevron {
      color: var(--c-primary-color) !important;
      &:first-of-type {
        display: none;
      }
    }
  }

  a.learn-more-button,
  button.learn-more-button {
    border: none !important;
    background: none !important;
    color: var(--primary-color) !important;
    font-weight: 700 !important;
    transition: 200ms ease color;
    text-decoration: none;
    padding: 0.5rem 1rem;
    font-family: 'Raleway', sans-serif !important;
    display: flex;
    align-items: center;
    letter-spacing: 2px;
    svg {
      transform: scale(0.75);
    }
    &:hover {
      background: none !important;
      border: none !important;
      color: var(--c-primary-darker-color) !important;
    }
  }

  .p-dropdown-items-wrapper {
    max-height: 500px !important;
  }

  .select-btn,
  .p-button {
    margin: 1rem 0;
  }

  .p-breadcrumb-category {
    margin-bottom: 1rem;
  }

  .p-breadcrumb-category .p-menuitem-text {
    font-family: 'Raleway', sans-serif;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: var(--text-color-secondary);
    font-weight: 400;
  }

  .p-dataview {
    .p-dataview-content {
      background: transparent !important;
      margin-bottom: 2rem;

      .grid {
        margin-right: -0.2rem !important;
        margin-left: -0.2rem !important;
        margin-top: 0 !important;
      }

      .product-card-col {
        padding: 0.2rem !important;
      }
    }
  }
}

.category-page__toast {
  width: calc(100vw - 4rem);
  margin: 1rem 1rem 0 0;
  position: absolute;
  top: 0;
  right: 0;
  @media screen and (min-width: 576px) {
    width: 300px;
  }
}

// .p-slider {
//   .p-slider-range {
//     background: var(--c-primary-color) !important;
//   }
//   .p-slider.p-slider-horizontal {
//     height: 2px !important;
//   }
//   .p-slider-handle {
//     height: 1rem !important;
//     width: 1rem !important;
//     border: 2px solid var(--c-primary-color) !important;
//   }
//   &:not(.p-disabled) .p-slider-handle:hover {
//     background: var(--c-primary-dark-color) !important;
//     border: 2px solid var(--c-primary-dark-color) !important;
//   }
// }

.filter-card {
  .p-card-body,
  .p-card-content {
    padding: 0 !important;
  }
  .p-tabview .p-tabview-nav {
    background: transparent !important;

    .p-tabview-nav-link {
      padding: 0.25rem !important;
    }
  }
  .p-tabview,
  .p-component {
    a {
      .p-chip,
      .p-component {
        letter-spacing: 0px !important;
        color: var(--text-color-secondary) !important;
        font-size: 0.75rem !important;
        background: var(--surface-invert) !important;
      }
    }
    .active {
      .p-chip,
      .p-component {
        letter-spacing: 0px !important;
        font-weight: bolder;
        color: var(--text-color) !important;
        background: var(--surface-invert) !important;
      }
    }
    .p-tabview-nav-container {
      .p-tabview-nav {
        border: none !important;
        button {
          background-color: var(--surface-invert) !important;
        } 
      }
    }
    .p-tabview-panels {
      background-color: var(--surface-invert) !important;
    }
  }

  .p-accordion {
    a {
      letter-spacing: 0px !important;
      color: var(--text-color-secondary) !important;
    }
    .active {
      color: var(--text-color-primary) !important;
      font-weight: bolder;
    }
  }

  .section_header {
    padding: 14px;
    padding-bottom: 0px;
    font-family: 'Raleway', sans-serif;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 400 !important;

    &::before {
      width: calc(100% - 24px);
      background: linear-gradient(
        to right,
        var(--primary-color) 50px,
        var(--surface-border) 50px
      );
    }

    &::after {
      margin: 12px 0 0;
    }
  }

  .range-inputs {
    .p-inputgroup {
      text-align: center;

      .p-inputgroup-addon {
        padding-left: 7px !important;
        padding-right: 5px !important;
        min-width: auto;
      }
    }
  }
}

.cart-summary__sidebar {
  .p-sidebar-header {
    display: none;
  }
  .p-sidebar-content {
    max-height: 95vh;
    padding: 1rem !important;
    overflow-y: scroll;
  }
  .cart-summary__list {
    max-height: 60vh;
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #999;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover,
    &::-webkit-scrollbar-thumb:active {
      background-color: #888;
    }
    &::-webkit-scrollbar-track {
      background-color: #ddd;
      border-radius: 4px;
    }
  }
}

.sidebar-filter {
  max-width: 90vw;
}

//removed background color from paginator button and hover styling:
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  margin: 2rem 0;
  background-color: transparent !important;
  color: var(--text-color);
  font-weight: 600;
  &:hover {
    color: var(--text-color-secondary);
  }
}

// clear filters button:
.p-button-outlined {
  &:hover {
    color: var(--primary-color) !important;
  }
}
