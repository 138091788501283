.hubspot-chat {
  button.p-button.hubspot-chat__btn {
    color: var(--primary-color-text);
    background-color: var(--primary-color) !important;
    border: none;
    &:active {
      background-color: var(--primary-color) !important;
      border: none;
    }
  }
  a {
    color: var(--primary-dark-color);
  }
}
