.store-nav-bar-wrapper {
  font-family: Raleway, sans-serif;
  width: 100%;
  left: 0;
  z-index: 1010;
  background-color: var(--surface-card);
  padding: 0rem;
  margin: 0 auto;
  transition: all 200ms ease-in-out !important;
  top: 0;
  position: sticky;
  * {
    text-transform: uppercase;
  }
  @media only screen and (min-width: 960px) {
    top: 0;
    bottom: unset;
  }

  &.sticky {
    box-shadow: 0 0 5px var(--text-color-secondary) !important;

    .StoreNavBar {
      .store-nav-top {
        display: none !important;
      }
    }
  }
  &.videoNavOverlay {
    background-color: rgba(255, 255, 255, 0);
    width: 100%;
    position: fixed;
    z-index: 1010;
    top: 0 !important;
    left: 0;
    right: 0;
    @media only screen and (min-width: 960px) {
      position: fixed;
      a,
      span,
      img {
        filter: drop-shadow(0px 0 2px rgba(0, 0, 0, 0.05));
        transition: filter 200ms ease;
      }
    }

    .p-megamenu-submenu {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      * {
        filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));
      }
    }

    .store-nav-lower-mega.p-megamenu .p-menuitem {
      &:hover,
      &:focus-within > .p-menuitem-link span {
        color: var(--primary-color) !important;
      }
      > .p-menuitem-link span {
        color: #fff !important;
      }
    }
    & .wrapper {
      .p-menuitem-link {
        .p-menuitem-text,
        .p-menuitem-icon {
          color: white;
          letter-spacing: 2px;
          font-weight: 400;
          font-size: 12px;
        }
      }
    }

    .p-megamenu
      .p-megamenu-root-list
      > .p-menuitem
      > .p-menuitem-link:hover
      span,
    .p-megamenu
      .p-megamenu-root-list
      > .p-menuitem
      > .p-menuitem-link:hover
      .p-submenu-icon {
      color: var(--primary-color) !important;
    }

    .store-nav-lower-mega.p-megamenu .p-megamenu-submenu .p-menuitem-link span {
      color: var(--primary-color) !important;
      &:hover {
        color: var(--primary-color) !important;
        cursor: pointer;
      }
    }
    &.solid-bg {
      background-color: var(--surface-card);
      .store-nav-lower-mega.p-megamenu .p-menuitem > .p-menuitem-link span {
        color: var(--primary-color) !important;
      }

      .p-tieredmenu .p-menuitem-link {
        .p-menuitem-icon,
        .p-menuitem-text {
          color: var(--primary-color);
        }
      }
      @media only screen and (min-width: 960px) {
        a,
        span,
        img {
          filter: drop-shadow(0px 0 0px rgba(0, 0, 0, 0));
        }
      }
    }
  }
  .p-megamenu-submenu {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    .p-menuitem {
      width: 100%;
    }
  }
  .crwd-logo-mobile {
    width: 100%;
    max-width: 120px;
    @media only screen and (min-width: 400px) {
      max-width: 160px;
    }
  }
  .pi.pi-user {
    font-size: 1.25rem;
  }
}
.StoreNavBar {
  &.hide {
    display: none;
  }
  a {
    text-decoration: none;
    color: var(--text-color);
    transition: all 200ms;
    text-transform: capitalize;
    &:hover,
    &:focus-within {
      color: var(--primary-color);
    }
    &:focus-visible {
      outline: 2px solid var(--primary-dark-color) !important;
    }
  }
  .p-menubar-start {
    flex: 1 !important;
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      img {
        width: 240px;
        height: 60px;
        margin: 0;
        display: block;
        object-fit: contain;
        @media only screen and (min-width: 576px) {
          width: 320px;
          height: 80px;
        }
        @media only screen and (min-width: 960px) {
          width: 300px;
          height: 75px;
        }
      }
    }
    .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
      color: var(--text-color);
      background-color: transparent;
    }

    .p-tieredmenu .p-menuitem-link {
      background-color: transparent;
      .p-menuitem-icon,
      .p-menuitem-text {
        color: var(--primary-color);
      }
      &:hover {
        background-color: transparent !important;
        .p-menuitem-icon,
        .p-menuitem-text {
          color: var(--primary-color) !important;
        }
      }
    }
    .p-tieredmenu
      .p-menuitem.p-menuitem-active
      > .p-menuitem-link
      .p-menuitem-text,
    .p-tieredmenu
      .p-menuitem.p-menuitem-active
      > .p-menuitem-link
      .p-menuitem-icon {
      color: var(--text-color);
    }
  }

  .store-nav-middle {
    padding: 0 1rem;
    button {
      min-width: 4.1rem;
    }
    .offset-nav-items {
      margin-left: 0;
      @media only screen and (min-width: 961px) {
        margin-left: -50px;
      }
      @media only screen and (min-width: 1200px) {
        margin-left: 0px;
      }
    }
    .logo-wrapper {
      text-align: center;
      img {
        width: 250px;
      }
    }
  }

  .user-info-menu {
    padding: 0;
    width: 100%;
    margin: 0;
    border: none;
    color: var(--text-color) !important;
    background: transparent;

    ul {
      width: 100%;
      .p-menuitem-active > .p-submenu-list {
        display: block;
        left: 0;
        top: 30px;
      }
    }
    li {
      padding: 0rem;
    }
    a.p-menuitem-link,
    li {
      color: var(--text-color) !important;
      text-transform: capitalize;
      font-size: 12px !important;
      line-height: 44px;
      letter-spacing: 2px;
      font-weight: 400;
    }
    .p-tieredmenu {
      background: transparent;
      border: 1px solid var(--text-color-secondary);
    }
    .nav-email-item {
      > a .p-menuitem-text {
        text-transform: lowercase;
      }

      .p-menuitem-link {
        @media only screen and (min-width: 961px) and (max-width: 1100) {
        }
      }
      a.p-menuitem-link .p-menuitem-text {
        @media only screen and (min-width: 960px) {
          white-space: initial;
          overflow: initial;
          text-overflow: initial;
          max-width: initial;
        }
        @media only screen and (min-width: 961px) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 10ch;
        }
        @media only screen and (min-width: 1200px) {
          max-width: 15ch;
        }
        @media only screen and (min-width: 1500px) {
          max-width: 30ch;
        }
      }
      &.p-menuitem-active {
        .p-submenu-list.p-submenu-list-flipped {
          box-shadow: 4px 4px 5px rgb(0 0 0 / 15%) !important;
          z-index: 10;
          .p-menuitem .p-menuitem-link .p-menuitem-text {
            color: var(--text-color);
          }
        }
      }
    }

    .p-tieredmenu .p-menuitem-active > .p-submenu-list-flipped {
      left: 0 !important;
    }

    .p-tieredmenu .p-menuitem-active > .p-submenu-list {
      display: block;
      left: 0 !important;
      top: 100%;
    }

    .p-submenu-icon {
      display: none;
    }
    .p-tieredmenu {
      padding: 0;
      color: var(--text-color-secondary);
      border: none;
      width: auto;
    }
  }

  .store-nav-lower {
    &.p-menubar {
      justify-content: center !important;
      max-width: 1440px;
      margin: 0 auto;
      background-color: var(--surface-50);
      border: none;
    }
  }
}
.store-nav-mobile-menu {
  .p-menubar-button {
    display: flex !important;
  }
  .p-menubar-root-list {
    @media only screen and (min-width: 961px) {
      display: none;
    }
  }
  &.p-menubar-mobile-active {
    .p-menubar-root-list {
      @media only screen and (min-width: 961px) {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 100px;
        width: 100%;
        z-index: 1000;
        background-color: var(--surface-50);
        .p-menuitem {
          width: 100%;
          .p-menuitem-link {
            justify-content: flex-start;
            .p-submenu-icon.pi.pi-angle-down {
              margin-left: auto;
            }
          }
        }
      }
    }
  }
  &.p-menubar {
    background-color: var(--surface-card);
    border: none;
    border-radius: 0;
    border-bottom: none;
    width: 100%;
    min-width: max-content;

    .nav-email-item {
      > a .p-menuitem-text {
        text-transform: lowercase;
      }
    }

    .p-menubar-root-list {
      box-shadow: 0px 10px 12px -15px var(--text-color) !important;
    }
  }
  .logo-mobile {
    transform: scale(0.75);
    @media only screen and (min-width: 340px) {
      transform: scale(1);
    }
  }
}

.store-nav-lower-mega {
  padding-bottom: 0 !important;
  .p-menuitem.active-route a.p-menuitem-link {
    .p-menuitem-text,
    .p-submenu-icon.pi.pi-angle-down {
      color: var(--text-color) !important;
      font-weight: bold !important;
      &:before {
        color: var(--text-color) !important;
      }
    }
  }

  > ul {
    gap: 20px;

    a {
      padding: 1.3rem 1rem !important;
    }
  }
  &.p-megamenu {
    background-color: transparent;
    border: none;
    align-items: center;
    justify-content: center;

    .p-menuitem {
      &:hover {
        .p-megamenu-panel {
          display: block;
        }
      }

      > .p-menuitem-link {
        span {
          color: var(--text-color-secondary) !important;
        }

        .p-submenu-icon {
          margin-left: 0.1rem !important;
        }

        &:not(.p-disabled):hover {
          background: transparent !important;
          span {
            color: var(--text-color) !important;
          }
        }
      }

      &.p-menuitem-active {
        > .p-menuitem-link {
          background: transparent !important;
          span {
            color: var(--text-color) !important;
          }
        }
      }
    }

    .p-megamenu-panel {
      z-index: 1500;
      left: initial;
      right: -50%;
      top: 56px !important;
      box-shadow: 4px 4px 5px rgb(0 0 0 / 15%) !important;
      border-top: 2px solid var(--primary-color);
      background: var(--surface-50);

      .p-megamenu-submenu {
        padding: 0;
        width: auto;
        @media only screen and (min-width: 720px) {
          min-width: 8rem;
        }
        @media only screen and (min-width: 1200px) {
          min-width: 16rem;
        }
        // * changed styling here in order to add nested links for the magamenu-submenu-headers:
        .p-megamenu-submenu-header {
          display: none;
        }
        .p-megamenu-submenu-header--custom-link {
          color: var(--text-color);
          text-transform: uppercase;
          font-family: 'Raleway', sans-serif;
          font-size: 14px;
          font-weight: 600 !important;
          letter-spacing: 0.12em;
          background: transparent;
          text-decoration: none;
          margin-left: 0.82rem;
          padding: 0 0 1rem 0 !important;
        }
      }

      .p-menuitem-link {
        font-weight: 500 !important;
        line-height: 1.42857143 !important;
        font-size: 14px !important;
        color: var(--text-color-secondary);
        transition: all 0.3s ease-in-out;
        font-family: Raleway, sans-serif;
        text-transform: capitalize;
        padding: 0.5rem 1rem !important;

        &:not(.p-disabled):hover {
          background: transparent;
          span {
            color: var(--primary-color) !important;
          }
        }
      }
      .p-megamenu-col-2 {
        width: 32% !important;
      }
      .p-megamenu-grid {
        display: flex;
        flex-wrap: wrap;
      }
    }
    .p-megamenu-root-list {
      li {
        &:nth-child(3) {
          .p-megamenu-panel {
            width: 90vw;
            left: calc(50% - 38vw);
            padding-bottom: 1rem;
          }
        }
      }
    }
  }
}

.cart-wrapper {
  position: relative;
  &:focus-visible {
    outline: 2px solid var(--primary-color) !important;
  }
  &:hover {
    color: var(--text-color-secondary) !important;
    background: none !important;
    border: none !important;
  }

  .nav-cart-quantity {
    background-color: var(--primary-color);
    color: var(--primary-color-text);
    font-weight: bold;
    z-index: 2;
    opacity: 1;
    transition: all 200ms;
    width: 20px;
    height: 20px;
    position: absolute;
    line-height: 20px;
    border-radius: 50%;
    text-align: center;
    font-size: 12px;
    letter-spacing: 0;
    top: -4px;
    right: -7px;
    @media screen and (min-width: 992px) {
      width: 25px;
      height: 25px;
      top: -4px;
      right: -18px;
      line-height: 25px;
    }
    &.cart-zero {
      background: var(--primary-color);
    }
  }
}

.nav-mobile-bottom {
  background-color: var(--surface-card);
  bottom: 0;
  .menu-bars {
    color: var(--text-color-primary) !important;
  }
}

.p-sidebar-right {
  min-width: 34% !important;
}

.p-sidebar-bottom {
  min-height: 90vh;
  .p-menu {
    width: 100%;
    border: none;
    font-family: inherit;
    margin-bottom: 2rem;
    a.img {
      padding-left: 4rem;
    }
    a {
      border-bottom: 0.15px solid var(--underline);
    }
    a,
    button,
    .logo-wrapper {
      &:focus-within {
        outline: 1px solid var(--primary-dark-color) !important;
      }
    }
    .p-submenu-header {
      margin: 12px 0 0 0;
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 0.3em;
      text-align: left;
      color: var(--text-color);
      &.nav-email-item {
        font-size: 13.2px !important;
        text-transform: lowercase;
        letter-spacing: 2px !important;
        font-weight: 600 !important;
        background-color: transparent;
        border-bottom: 0.15px solid var(--underline);
        margin: 0;
      }
    }
  }
}
