.column {
  border-radius: 20px;
  padding: 0;
  margin: 0 10px 50px 10px;
  border: none !important;
}

.product-card {
  transition: box-shadow 500ms ease !important;
  border-radius: 20px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 3px 6px rgba(0, 0, 0, 0.23) !important;
  &:hover {
    box-shadow: 0px 19px 38px -5px rgba(0, 0, 0, 0.3),
      0px 15px 12px -5px rgba(0, 0, 0, 0.22) !important;
  }
  img {
    height: 20em;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    object-fit: cover;
    display: block;
    &.dontclick,
    &.theduttons {
      height: 20em;
    }
    &.deterioration {
      object-fit: contain;
      background: radial-gradient(#1c1c1c, #020202);
    }
  }
  .p-card-body {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .p-card-content {
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;

    .product-description {
      p {
        margin-top: 0 !important;
        margin-bottom: 0.5rem !important;
        font-size: 0.85rem;
        font-weight: 400;
        &:last-of-type {
          margin-bottom: 0 !important;
        }
      }
      br {
        display: none;
      }
      ul {
        font-size: 0.85rem;
        font-weight: 400;
        padding: 0 1rem;
      }
    }
  }

  .add-to-cart-button {
    background-color: #fb6900 !important;
    border: none;
    cursor: pointer;
    letter-spacing: 2px;
    font-size: 13px;
    font-weight: 700;
    &:hover,
    &:focus-visible {
      background: #c83600 !important;
    }
  }

  .view-product-button {
    font-size: 13px;
    font-weight: 900;
    color: #3058cb;
    text-transform: uppercase;
    letter-spacing: 2px;
    border: none;
    cursor: pointer;
    background: none;
    &:hover {
      color: #7170ff;
    }
  }
}

.p-card-title {
  text-align: left;
  color: #000000;
}

.product-name {
  color: #000000;
}

.price {
  color: var(--text-color);
  font-weight: bold;
  font-size: 2rem !important;
  text-align: center;
  .product-subscription {
    font-size: 0.75rem !important;
    opacity: 0.55;
    text-transform: lowercase;
  }
}

.product-description {
  font-style: normal;
  text-align: left;
  font-weight: 600;
  font-size: 15px;
  line-height: 1rem;
  // color: var(--text-color);
  padding-bottom: 1rem;
  ul {
    padding: 0;
    list-style-position: outside;
    margin-left: 18px;
    li {
      margin-bottom: 0.5rem;
    }
  }
}

.p-card-content {
  .product-description {
    ul {
      list-style-position: outside;
      margin-left: 8px;
      li {
        margin-bottom: 0.25rem;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .section-text {
    text-align: center;
  }
  .mobile-package-cards {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .column {
    width: calc(100% - 20px) !important;
    max-width: unset !important;
    margin: 0 10px 30px 10px;
    height: calc(100% - 30px);
  }
}

.package-details-modal {
  .p-dialog-header {
    background: var(--surface-ground) !important;
  }
  .p-dialog-content {
    background: var(--surface-ground) !important;
    padding: 0;
  }
  .p-dialog {
  }

  .p-dialog-header {
    border-bottom: 1px solid #e0e0e0;
  }

  .p-dialog-header-icons {
    button {
      &:focus-visible {
        outline: 2px solid #005fcc !important;
      }
    }
  }
}

.packages-details__toast {
  width: calc(100vw - 4rem);
  margin: 1rem 1rem 0 0;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999 !important;
}

@media screen and (min-width: 576px) {
  .packages-details__toast {
    width: 300px;
  }
}
