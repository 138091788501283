.logo-image {
  img {
    width: 100px;
    object-fit: contain;
  }
  @media (min-width: 600px) {
    img {
      width: 250px;
    }
  }
}

.font-oswald {
  font-family: Oswald !important;
  &.font-regular {
    font-weight: 400 !important;
  }
}

.font-montserrat {
  font-family: Montserrat !important;
  &.font-black-900 {
    font-weight: 900 !important;
  }
  &.font-regular {
    font-weight: 400 !important;
  }
}

.signup-hero-light {
  background-color: rgba(255, 255, 255, 0.5);
}
.signup-hero-dark {
  background-color: rgba(0, 0, 0, 0.5);
}
.signup-hero-content {
  max-width: 1480px;
  padding-top: 120px;
  padding-bottom: 120px;
  margin: auto;
}

.signup-content {
  max-width: 1480px;
  margin: auto;
}

.text-black {
  color: black;
}

.font-size-1-1 {
  font-size: 1.1rem;
}

.signup-checks {
  li {
    i {
      color: white;
      margin-right: 10px;
      background-color: var(--primary-color) !important;
      border-radius: 100%;
      padding: 4px;
      max-width: 15px !important;
      max-height: 15px !important;
    }

    i:before {
      font-size: 8px !important;
      align-items: center;
      align-self: center;
      margin-bottom: 4px;
      padding-bottom: 4px;
      display: flex;
    }
  }
}

.img-border img {
  max-width: 100%;
  box-shadow: rgb(0 0 0 / 30%) 0px 2px 5px 2px;
  border: 3px solid rgb(255, 255, 255);
  padding: 0px;
}

.text-6xl {
  line-height: 55px;
}

.text-5xl {
  line-height: 55px;
}

.wide-width-container {
  max-width: 1480px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.bg-black-section {
  background-color: #000;
  height: 680px;
  width: 100%;
}