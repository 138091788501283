.my-story {
  &__text {
    margin-bottom: 44px;
    @media only screen and (min-width: 768px) {
      max-width: 600px;
    }
    p {
      margin-top: 0;
    }
  }
  &__right {
    background-color: #111111;
    box-shadow: 0px 30px 24px -20px #000000 !important;
    -webkit-box-shadow: 0px 30px 24px -20px #000000  !important;
    -moz-box-shadow: 0px 30px 24px -20px #000000  !important;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 272px;
    @media only screen and (min-width: 360px) {
      width: 300px;
    }
  }
  .react-media-player {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media only screen and (min-width: 768px) {
      &::before {
        content: '';
        position: absolute;
        top: -5px;
        left: 75px;
        height: 25px;
        width: 150px;
        background-color: #111;
        z-index: 99;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
  .react-media-player,
  .react-media-player > * {
    height: 100%;
    width: 100%;
    border-radius: 30px;
    @media only screen and (min-width: 768px) {
      height: calc(100% - 10px);
      width: calc(100% - 10px);
    }
  }
}
