.video-page {
  min-height: 90vh;
  width: 100vw;
  background: linear-gradient(25deg, rgba(#8886FF, 1) 0.25%, rgba(#07065B, 1) 97%) ;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;

  h1 {
    color: #fff;
    font-size: 2.5rem;
    margin-bottom: 1rem;
    font-weight: bold;
    text-align: center;
  }
  
  .react-media-player {
    margin: 0 auto;
    width: 90%;
    @media only screen and (min-width: 767px) {
      width: 50%;
    }
    margin-bottom: 1rem;
    .bc-player-default_default {
      width: 100% !important;
      padding-top: 56.25%;
      margin: 0 auto;
    }
  }
}