.product-feature-related-item-card {
  .p-card-header,
  .p-card-body {
    margin: 0 auto !important;
  }
  .related-product-title {
    color: var(--text-color);
    font-size: 1.438rem !important;
    text-align: center;
  }
}
