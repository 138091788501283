#testimonials {
  .p-carousel {
      position: relative;
      margin: 0 auto;
      width: 85vw;
      @media only screen and (min-width: 767px) {
        width: 100%;
      }
      .p-carousel-content {
        overflow: visible;
      }
    }
    
    .review-title-text {
      @media only screen and (max-width: 415px) {
        font-size: 1.67rem!important;
      }
    }
    
    .review-subtitle-text {
      @media only screen and (max-width: 400px) {
        font-size: .98rem!important;
      }
    }
    
    .p-carousel-container {
        min-height: 18rem;
        position: relative;
        background-color: #F1F1F1;
        @media only screen and (max-width: 576px) {
            min-height: 22rem;
            padding: 0 .5rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &:before, &:after {
          content: '';
          background-repeat: no-repeat;
          background-size: contain;
          display: block;
          height: 2.5rem;
          aspect-ratio: 1;
          position: absolute;
        }
        &:before {
          background-image: url(../../../../assets/review-svg/left-quote.svg);
          top: 0.8rem;
          left: 0.8rem;
          @media only screen and (min-width: 767px) {
            top: 1rem;
            left: 1rem;
          }
        }
        &:after {
          background-image: url(../../../../assets/review-svg/right-quote.svg);
          bottom: 0.8rem;
          right: 0.8rem;
          @media only screen and (min-width: 767px) {
            bottom: 1rem;
            right: 1rem;
          }
        }
        .p-carousel-next-icon, .p-carousel-prev-icon {
          &:before {
            color: #8886FF;
            background: #fff;
            border: 1px solid #9D9D9D;
            height: 1.5em;
            aspect-ratio: 1;
            border-radius: 50%;
            padding: 5px;
            transition: all 200ms ease;
          }
          &:hover,&:focus-visible {
            &:before {
              color: #fff;
              background: #8886FF;
              border: 1px solid #8886FF;
            }
          }
        }
        .p-link {
          position: absolute;
          z-index: 1s;
          &:first-of-type {
            top: 50%;
            left: -25px;
          }
          &:last-of-type {
            top: 50%;
            right: -25px;
          }
          @media only screen and (min-width: 767px) {
            position: initial;
          }
            &:focus-visible {
                border: 1px solid #0400FF;
                background: #e9ecef;
            }
        }
        .review--wrapper {
          flex: 1;
          height: 100%;
          .review--name {
            margin: 0;
            letter-spacing: 2px;
            font-size: 18px;
            font-weight: 900;
            color: #000;
            text-align: center;
          }
          .review--ocupation {
            font-size: 12px;
            margin: 0 0 12px 0;
            color: #000;
            text-align: center;
            width: 50%;
            letter-spacing: 0px;
            @media only screen and (min-width: 767px) {
              letter-spacing: 2px;
              line-height: 1.5rem;
              width: 100%;
            }
          }
        }
        .p-carousel-items-container {
          width: 100%;
          padding: 2rem 0rem;
        }
        .p-carousel-items-content {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
        }
     }
    
    .p-carousel-indicators {
        display: none !important;
    }
    
    .review-quote {
        position: absolute;
        &.top {
            top: 1rem; 
            left: 1rem;
        }
        &.bottom {
            bottom: -2rem;
            right: 1rem;
        }
    }
}
