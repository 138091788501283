.linked-product__card--upsell {
  height: 100%;
  max-width: 170px;

  .upsell-button {
    color: var(--primary-color);
    font-weight: bold;
    text-align: center;
    background: none;
    border: none;
    text-transform: uppercase;

    font-size: 0.8rem;
    &:hover {
      background: none !important;
      color: var(--primary-color) !important;
    }
  }

  .p-card {
    box-shadow: none;
    // border: 0.5px solid var(--surface-500);
  }

  .p-card-header {
    padding: 0rem !important;
    flex: 1;
    display: flex;
    background: var(--surface-c);
    picture {
      aspect-ratio: 1;
      img {
        aspect-ratio: 1;
      }
    }
  }
  .p-card-body {
    padding-top: 0;
    background: var(--surface-ground) !important;
  }
  .p-card-content {
    display: flex;
    flex-direction: column;
    padding: 0 !important;
  }
  .p-card {
    background-color: inherit !important;
  }
}

.linked-product__card--related {
  height: 100%;
  .p-card {
    border-color: var(--surface-border);
  }

  .p-card-header {
    padding: 0rem !important;
    flex: 1;
    display: flex;
    picture {
      aspect-ratio: 1;
      img {
        aspect-ratio: 1;
      }
    }
  }
  .p-card-body {
    padding: 0.5rem !important;
    flex: 1;
    display: flex;
    width: 100%;
    background: var(--surface-ground) !important;
  }
  .p-card-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 !important;
    align-items: flex-start;
    width: 100%;
  }
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
