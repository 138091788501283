// @import '../pages/shop-home-page/shop-scss/shop-index.scss';

.product {
  .p-tabviews {
    .p-tabview-nav {
      border: none !important;

      .p-unselectable-text > button {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      .p-unselectable-text {
        margin-right: 1.5rem;
      }
      .p-tabview-selected .p-tabview-nav-link {
        color: var(--primary-color) !important;
        text-decoration: underline;
        text-underline-offset: 4px;
        text-decoration-color: var(--primary-color) !important;
        background-color: none;
      }
      .p-tabview-nav-link {
        border-color: transparent !important;
        font-weight: normal !important;
        text-transform: uppercase;
        font-size: 0.75rem;
        color: var(--text-color) !important;
      }
    }
    .p-tabview-panels {
      padding: 0 !important;
    }
    .product-description {
      padding-bottom: 0 !important;
      font-family: Raleway, sans-serif;
      > * {
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-weight: normal;
      }
    }
  }

  .p-tabview {
    background-color: var(--surface-ground) !important;
    border: 1px solid var(--surface-400) !important;
    .p-tabview-nav {
      justify-content: space-evenly !important;
      background-color: var(--surface-ground) !important;

      button.p-tabview-nav-link {
        padding: 1.5rem 1rem 0.5rem;
        background-color: var(--surface-ground) !important;
        .p-tabview-title {
          text-transform: uppercase;
        }
      }
    }
    .p-tabview-panels {
      background-color: transparent;
    }
  }

  .p-bundle-header {
    color: var(--primary-color) !important;
  }
  .vimeo-player-container {
    position: relative;
    padding-top: 56.25%;
    width: 100%;

    iframe {
      // max-width: 100%;
      // margin: 0 auto;
      // &:not(:first-of-type) {
      //   margin-top: 20px;
      // }
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-image: url('../assets/vido-bg.jpg');
    }
  }
  .reviews-containers {
    .p-scrollpanel-wrapper {
      border-right: 9px solid #fff;
    }
    .p-scrollpanel-bar {
      background-color: var(--primary-color);
      opacity: 1;
      transition: background-color 0.3s;
    }
    .p-scrollpanel-bar:hover {
      background-color: var(--primary-color);
    }
    .p-scrollpanel-content {
      padding-bottom: 0;
    }
  }
  .write-review-btn {
    background-color: #fb6900 !important;
    border: none;
    cursor: pointer;
    letter-spacing: 2px;
    font-size: 13px;
    font-weight: 700;
    &:hover,
    &:focus-within {
      background: #c83600 !important;
    }
  }
  .dark-text {
    // color: $dark-text;
  }
  .toggle-description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
    transition: all 200ms ease;
    p {
      margin-bottom: 1rem;
    }
    &.expand {
      transition: all 200ms ease;
      overflow: unset;
      -webkit-line-clamp: unset;
    }
  }
  .h1-product-name {
    font-size: 1.75rem;
    @media screen and (min-width: 989px) {
      font-size: 2.5rem;
    }
    margin: 0 0 0 0 !important;
    color: var(--primary-color) !important;
  }
}
