.checkout__summary {
  .p-orderlist-list {
    min-height: unset;
  }
  .p-orderlist-controls {
    display: none;
  }
  .summary__cart__item {
    display: flex;
    align-items: center;
    .summary__cart__item__image {
      position: relative;
      display: flex;
      img {
        max-width: 35px;
        object-fit: contain;
        margin: 0 auto;
        aspect-ratio: 1;
        border-radius: 4px;
        border: 1px solid var(--surface-border);
      }
      span {
        background-color: var(--primary-color);
        color: var(--primary-color-text);
        font-weight: bold;
        top: -4px;
        right: 0px;
        z-index: 2;
        opacity: 1;
        transition: opacity 200ms;
        width: 25px;
        position: absolute;
        line-height: 25px;
        border-radius: 50%;
        text-align: center;
        font-size: 12px;
        letter-spacing: 0;
        transform: scale(0.7);
        @media screen and (min-width: 992px) {
        transform: scale(0.9);
        }
      }
    }
  }
  .summary__cart__title {
    font-size: 12px;
    line-height: 1.3em;
  }
  .p-panel-header {
    background: none !important;
    border: none !important;
  }
  .p-panel-content {
    background: none !important;
    border: none !important;
    padding: 0.25rem 1rem !important;
  }

  .order--sumary--toggle {
    background: var(--surface-invert);
    color: var(--surface-900);
    border: none;
    &:hover {
      background-color: var(--surface-500) !important;
    }
  }
}
