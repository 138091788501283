#shop-blog-details {
  max-width: 1480px;
  margin: 0 auto;
  padding: 50px 0;
  .blog-title {
    text-align: center;
    color: var(--text-color);
    font-weight: bold;
    font-size: 2.2rem;
  }
  .blog-content {
    color: var(--text-color-secondary);
    h1 {
      strong {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        color: var(--text-color);
      }
    }
    h3 {
      font-size: 24px;
      line-height: 1.3;
      font-weight: 400;
      margin: 0px;
      font-family: Oswald, sans-serif;
      padding: 0 0 10px;
      color: var(--text-color);

      strong {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
      }
    }

    h4 {
      font-size: 20px;
      font-family: Oswald, sans-serif;
      padding: 0 0 10px;
      line-height: 34px;
      font-weight: 400;
      color: var(--text-color);
    }

    h5 {
      font-size: 15px;
      font-family: Oswald, sans-serif;
      padding: 0 0 10px;
      line-height: 30px;
      font-weight: 300;
      color: var(--text-color);
    }

    p {
      font-family: Montserrat, sans-serif;
      font-size: 18px;
      line-height: 1.5;
      padding: 0 0 10px;
      margin: 0;
    }

    img {
      border: 0;
      vertical-align: top;
      height: auto;
      max-width: 100%;
    }

    strong {
      font-family: Montserrat, sans-serif;
      font-size: 18px;
      line-height: 1.5;
      color: var(--text-color);
    }

    ul {
      list-style: disc;
      margin-left: 30px;
      margin: 0;
      padding: 10px 0;

      li {
        padding: 2px 0 2px 15px;
        position: relative;
        margin: 0px;
        font-family: Montserrat, sans-serif;
        font-size: 18px;
        line-height: 1.5;

        &:before {
          display: none;
        }
      }
    }

    p + p {
      padding-top: 10px;
    }

    a {
      font-size: 18px;
      font-weight: 700 !important;
      line-height: 1.5;
      transition: all 0.4s ease-in-out;
      color: var(--text-color);
      letter-spacing: 1px !important;
    }

    blockquote {
      margin: 20px 0;
      padding: 0 0 0 20px;
      border-left: 3px solid var(--primary-color);
      font-style: italic;
      font-weight: 700;

      p {
        padding: 0 !important;
      }
    }
  }

  .block {
    background: var(--surface-card);
    padding: 15px;
    margin: 0 0 50px;
  }

  .block2 {
    margin: 50px 0 30px;

    .section_header {
      text-align: center;

      &:after {
        margin: 19px auto 24px;
      }
    }
  }
  .blog-author-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0.75rem;
    background: var(--surface-ground) !important;
    border: 0.5px solid var(--surface-500);
    height: fit-content;
    .author-header {
      font-size: 0.75rem !important;
    }
    .author-bio {
      font-family: 'TradeGothicLight', sans-serif;
    }

    @media screen and (max-width: 420px) {
      .p-avatar.p-avatar-xl {
        width: 3rem;
        height: 3rem;
        font-size: 1rem;
      }
    }
  }
  iframe {
    width: 100% !important;
  }
  .p-avatar img {
    object-fit: cover;
  }
}

.spinner-wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shop-now {
  min-width: 50%;
  min-height: auto;
  .p-card-content {
    padding: 0 !important;
  }
  .p-card-body {
    padding: 0.3rem !important;
  }
  .shop-now-image {
    // min-height: 25%;
    max-width: 50%;
  }
}

.shop-now-btn {
  place-self: end;
  border-radius: 0px;
  margin-top: 0.5rem;
  width: 100% !important;
  &:hover,
  &:active,
  .p-button:enabled:hover {
    border: none !important;
    background-color: var(--primary-darker-color);
  }
}

.cta-item {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-width: 50%;
  background: var(--surface-ground) !important;
  border: 0.5px solid var(--surface-500);
  padding: 10px 6px !important;
  margin-right: 1rem;
  transition: all 200ms ease;
  .shop-now-image {
    max-width: 50%;
  }

  &.vendorLink {
    flex-direction: column;
    border: 0.5px solid transparent;
    width: 75%;
    &:hover {
      background: var(--surface-ground) !important;
      .shop-now-image {
        opacity: 0.9;
      }
    }
    .shop-now-image {
      max-width: 100%;
      opacity: 1;
      transition: all 200ms ease;
    }
    .cta-readmore {
      bottom: 1rem;
    }
  }

  .cta-readmore {
    bottom: 0.3rem;
    font-family: 'Montserrat', sans-serif;
    font-size: 10px !important;
    text-transform: uppercase;
    letter-spacing: 0.1em !important;
    color: var(--primary-color-text) !important;
    text-decoration: none;
    span.p-button-label.p-c {
      font-weight: bold !important;
    }
    &:hover,
    &:focus-within {
      color: var(--primary-color);
    }
  }
}
