.video-wrapper {
  overflow: hidden;
  min-height: 70vh;
  max-height: 75vh;
  position: relative;

  .video-overlay-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 4rem 2rem;
    margin: auto;
    text-align: center;
    z-index: 3;
    position: absolute;
    &.align-bottom {
      @media screen and (max-width: 960px) {
        justify-content: flex-end;
        text-align: center;
      }
    }
    &.bottom-left {
      @media screen and (max-width: 960px) {
        margin: auto auto 2rem 4rem;
        align-items: flex-end;
        justify-content: flex-end;
        text-align: left;
      }
    }
    .title-text {
      font-size: 2.5rem;
      @media screen and (min-width: 960px) {
        font-size: 3.5rem;
      }
      text-shadow: 0em 0em 0.3em rgb(0 0 0 / 40%)
    }
  }
  .tagline-wrapper {
    @media screen and (max-width: 960px) {
      display: none;
    }
    position: absolute;
    bottom: 0px;
    right: 0;
    padding: 1rem 3rem;
    background-color: #fff;
    color: black;
    min-width: 320px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    p {
      margin: 0 auto;
      font-size: 20px;
      font-family: 'Oswald',Helvetica,Arial,Lucida,sans-serif;
    line-height: 1.3em;
    }
  }
  .background-image-only {
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 45vh;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 960px) {
      // background-attachment: fixed;
      min-height: 55vh;
    }
    // &::after {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background: var(--maskbg);
    //   z-index: 0;
    // }
  }
  .main {
    width: 100%;
    height: 100%;
  }
  
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
  }
}