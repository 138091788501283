.signup-page {
  .large-text {
    font-size: 2.0em;
    color: #000;
    @media screen and (min-width: 976px) {
      font-size: 2.85em;
    }
  }
  
  .p-button.p-component.text-center.w-full {
    border-radius: 5px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    padding: 0.3em 1em !important;
    line-height: 1.6em !important;
    span {
      font-weight: bold;
    }
  }
  .parsed-text {
    font-size: 16px;
    line-height: 1.3em !important;
    color: #231f20 !important;
    font-weight: 500 !important;
  }
}