.product-card-list-item {
  overflow: hidden;

  .product-details-hover {
    display: none;
  }
  .product-details-hover > * {
    margin: 0;
    padding: 0;
    color: var(--text-color-secondary);
  }

  .p-card-header:hover
    + .p-card-body
    > .p-card-content
    > .product-details-hover {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .p-card-header:hover
    + .p-card-body
    > .p-card-content
    > .product-details-hide {
    display: none;
  }

  a.product-link,
  button.product-link {
    text-decoration: none !important;
    letter-spacing: 0px !important;
    color: var(--primary-color);
    font-family: 'Raleway', sans-serif;
    font-size: 13.2px !important;
    background-color: transparent !important;
    border: none;
    padding: 0;
    // margin: 0;
    text-align: left;
    &:hover,
    &:enabled:active {
      color: var(--primary-dark-color) !important;
    }
    &:hover + .on-product-hover {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .product-card-image {
    aspect-ratio: 1;
    width: 100%;
    background-size: cover !important;
    background-position: center center !important;
    display: block;
    padding: 0;
    margin: 0;
    height: 180px;
    background-color: var(--surface-c) !important;
    @media screen and (min-width: 767px) {
      height: 280px;
    }
    @media screen and (min-width: 991px) {
      height: 380px;
    }
    @media screen and (min-width: 1199px) {
      height: 330px;
    }
  }
  .p-card-body {
    padding: 0 1rem !important;
    width: 100%;
    @media screen and (min-width: 767px) {
      padding: 1rem !important;
    }
  }
}


.product-card-col {
  margin: 0 0 0 0 !important;
  @media screen and (min-width: 767px) {
    &:last-of-type {
      margin: 0 auto 0 0 !important;
    }
  }
}