.cart-summary-sidebar {
  .cart-wrapper {
    position: relative;
    margin: 0 1.2rem 0 0;
    background: none;
    border: none;
    color: var(--primary-color) !important;
    width: max-content;
    overflow: visible;
    &:hover {
      color: rgba(var(--primary-color, 0.8)) !important;
      background: none !important;
      border: none !important;
    }

    i {
      z-index: 2;
      position: relative;
    }
    span {
      background: var(--primary-color);
      color: white;
      font-weight: bold;
      z-index: 2;
      opacity: 1;
      transition: all 200ms;
      width: 25px;
      position: absolute;
      line-height: 25px;
      border-radius: 50%;
      text-align: center;
      font-size: 12px;
      letter-spacing: 0;
      top: -16px;
      right: -18px;
      @media screen and (min-width: 992px) {
        top: -4px;
        right: -18px;
      }
      &.cart-zero {
        background: rgba(var(--text-color), 0.5);
      }
    }
  }
  .details {
    letter-spacing: 0px !important;
    font-weight: 400 !important;
    font-size: 13.5px !important;
  }

  .cart-summary-card {
    position: relative;
    border-top: 2px solid grey;
    min-width: 280px;
    @media screen and (min-width: 992px) {
      min-width: 300px;
    }
  }

  .title {
    color:grey !important;
  }

  .p-orderlist-list {
    min-height: unset;
  }
  .p-orderlist-controls {
    display: none;
  }
  .summary__cart__item {
    display: flex;
    align-items: center;
  }
  .summary__cart__item__image {
    display: flex;
    img {
      max-width: 50px;
      object-fit: contain;
      margin: 0 auto;
      aspect-ratio: 1;
      border-radius: 4px;
      border: 1px solid var(--surface-border);
    }
    span {
      background-color: var(--primary-color);
      color: var(--primary-color-text);
      font-weight: bold;
      top: -4px;
      right: -4px;
      z-index: 2;
      opacity: 1;
      transition: opacity 200ms;
      width: 25px;
      position: absolute;
      line-height: 25px;
      border-radius: 50%;
      text-align: center;
      font-size: 12px;
      letter-spacing: 0;
      @media screen and (max-width: 992px) {
        right: 4px;
      }
    }
  }

  .product-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 18ch;
    font-size: 10px;
  }
  .product-price {
    font-size: 12px;
  }
  .x-btn__container > .x-btn {
    padding: 0.5rem 1rem !important;
    color: var(--color-text);
    background-color: transparent !important;
    border-color: transparent !important;
    &:hover {
      color: var(--primary-color) !important;
      background-color: transparent !important;
      border-color: transparent !important;
    }
    &:focus-visible {
      // outline: 2px solid $focus !important;
    }
    .p-button-loading-icon {
      &::before {
        color: var(--text-color-secondary) !important;
      }
    }
  }
  .p-inputnumber {
    .p-button-icon {
      transform: scale(0.8);
      &::before {
        color: black;
        width: 10px !important;
      }
    }
    .p-inputtext {
      max-width: 40px;
      border-color: #ddd !important;
      box-shadow: none !important;
      text-align: center;
    }
  }
  .p-sidebar-close {
    color: var(--text-color);
    padding: 16px 12px;
    .p-button-label {
      text-decoration: underline !important;
      text-underline-offset: 0.3em;
    }
    border: transparent !important;
    background: transparent !important;
    outline: transparent;
    &:hover {
      background: transparent;
      color: rgba(var(--primary-color), 0.8) !important;
    }
    &:focus-visible {
      color: rgba(var(--primary-color), 0.8) !important;
      // outline: none;
    }
  }
}
