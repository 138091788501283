.nav {
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1010;
  background-color: var(--surface-card);
  border-bottom: 1px solid var(--surface-border) !important;
  padding: 0.5rem 1rem;
  margin: 0 auto;

  .p-tieredmenu {
    background: none;
    padding: 0;
    border: none;
    width: auto;
  }

  .p-tieredmenu .p-menuitem-active > .p-submenu-list-flipped {
    left: 0 !important;
  }

  .p-tieredmenu .p-menuitem-active > .p-submenu-list {
    display: block;
    left: 0 !important;
    top: 100%;
  }

  .p-submenu-icon {
    display: none;
  }

  &.hide {
    display: none;
  }
  border-bottom: 1px solid lightgray !important;
  margin: 0 auto;
  a {
    text-decoration: none;
    color: var(--text-color);
    transition: all 200ms;
    &:hover,
    &:focus-within {
      color: var(--c-primary-color);
    }
    &:focus-visible {
      // outline: none;
    }
  }
  .p-menubar-start {
    flex: 1 !important;
  }
  .crwd-nav-logo {
    width: 140px;
    margin: 0;
    display: block;
    object-fit: contain;
    padding: 12px 0;
    min-height: 50px;
    // @media only screen and (min-width: 576px) {
    //   width: 160px;
    //   min-height: 50px;
    // }
    @media only screen and (min-width: 960px) {
      width: 220px;
      min-height: 60px;
    }
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      .nav--user-info {
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 100%;
        min-width: 14rem;

        ul[role='menubar'] > .p-menuitem > .p-menuitem-link .p-menuitem-text {
          max-width: 7ch;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          text-transform: lowercase;
        }

        .user-wrapper {
          margin: 0 0.8rem;
          li.has-submenu {
            position: relative;
            ul {
              display: none;
            }
            &:hover,
            :focus-within {
              ul {
                display: flex;
                width: 200px;
                height: 100px;
                background-color: var(--surface-card);
                position: absolute;
                top: 30px;
                right: 0;
                align-items: center;
                justify-content: center;
                margin: 0;
                list-style: none;
                li {
                  width: 100%;
                  margin: 0;
                  padding: 0;
                }
                a {
                  color: var(--text-color);
                  text-align: center;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }
  }
  .nav-email {
    > .p-menuitem-link {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 12ch;
      @media only screen and (min-width: 320px) {
        max-width: 18ch;
      }
      @media only screen and (min-width: 500px) {
        max-width: 30ch;
      }
    }
  }
  .cart-wrapper {
    position: relative;
    margin: 0 0.8rem;

    i {
      z-index: 2;
      position: relative;
    }
    span {
      background: var(--primary-color);
      color: var(--primary-color-text);
      font-weight: bold;
      top: -18px;
      right: -18px;
      z-index: 2;
      opacity: 1;
      transition: opacity 200ms;
      width: 25px;
      position: absolute;
      line-height: 25px;
      border-radius: 50%;
      text-align: center;
      font-size: 12px;
      letter-spacing: 0;
      &.hide {
        opacity: 0;
      }
    }
  }
}
