// change check mark icon in primereact multiselect to primary color instead of default color white:
span.p-checkbox-icon.p-c.pi.pi-check {
  color: var(--primary-color) !important;
}

.filter-card .p-slider {
  max-width: 235px !important;
  .p-slider-range {
    max-width: 234px !important;
  }
  .p-slider-handle-end {
    left: 100% !important;
  }
}

.shop-categories {
  background-color: var(--surface-ground) !important;
}
.collection-aside {
  .p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    font-weight: 700;
  }
}
