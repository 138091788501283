.footer {

  &--top {
    background-color: #1a1a1a;
    position: relative;
    h5 {
      font-style: normal;
      font-weight: bold;
      font-size: 14.89px;
      line-height: 18px;
      letter-spacing: 0.2em;
    }
    p {
      color: #75749C;
    }
    a,
    button {
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.2em;
      opacity: 0.65;
      transition: opacity 200ms ease;
      &:hover, &:focus-within {
        opacity: 1;
      }
    }
    .footer-logo {
      width: 100%;
      height: 60px;
      margin-left: 0px !important;
      object-fit: contain;
      max-width: 240px;
      @media only screen and (min-width: 1200px) {
        width: 260px;
        height: 80px;
        margin-left: 0px !important;
      }
    }
  }

  &--bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #141414;
    p {
      color: #8C8C8C;
      padding: 0;
      font-size: 11px;
      font-weight: bold;
      line-height: 35px;
      margin: 1em 0;
      text-decoration: none;
    }
    a {
      color: #8C8C8C;
      padding: 0;
      font-size: 11px !important;
      font-weight: bold !important;
      line-height: 35px;
      margin: 1em 0;
      letter-spacing: unset !important;
      text-decoration: none;
      font-family: 'Raleway', sans-serif;
    }
  }
  &--scroll {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    @media only screen and (min-width: 600px) {
      right: 6rem;
    }
    @media only screen and (min-width: 1200px) {
      right: 12rem;
    }
    background-color: transparent;
    outline: transparent;
    border: none;
    border-radius: 50%;
    transition: all 200ms ease;
    padding: 0;
    margin: 0;
    &:hover, &:focus-within {
      filter: drop-shadow(0 0rem 0.05rem #8886FF) drop-shadow(0 0rem 0.15rem #7674ff);
    }
  }

  .footer-links {
    @media only screen and (max-width: 415px) {
      padding: 0!important;
    }
  }
}