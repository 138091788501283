.cart {
  .p-orderlist-list {
    min-height: unset;
  }
  .p-orderlist-controls {
    display: none;
  }
}

.cart__item {
  display: flex;
  align-items: center;
  .cart__item__image {
    img {
      max-width: 70px;
    }
  }
  .p-inputnumber {
    .p-button-icon {
      transform: scale(0.8);
      &::before {
        color: black;
        width: 10px !important;
      }
    }
    .p-inputtext {
      max-width: 40px;
      border-color: #ddd !important;
      box-shadow: none !important;
      text-align: center;
    }
  }
  .x-btn__container > .x-btn {
    padding: 0.5rem 1rem !important;
    color: var(--c-gray-color);
    background-color: transparent !important;
    border-color: transparent !important;
    &:hover {
      color: var(--c-black-color);
      background-color: transparent !important;
      border-color: transparent !important;
    }
    .p-button-loading-icon {
      &::before {
        color: var(--c-gray-color) !important;
      }
    }
  }
}

.cart-item__list {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover,
  &::-webkit-scrollbar-thumb:active {
    background-color: var(--primary-color);
  }
  &::-webkit-scrollbar-track {
    background-color: var(--surface-border);
    border-radius: 4px;
  }
}

.cart-item__image {
  display: flex;
  img {
    width: 100%;
    object-fit: contain;
    height: 50px;
    @media only screen and (min-width: 992px) {
      height: 75px;
    }
  }
}

.cart-item__quantity {
  &--mobile {
    .p-button.p-button-icon-only,
    .p-inputnumber-input {
      width: 2.357rem !important;
      text-align: center;
    }
  }
  &--desktop {
    .p-button.p-button-icon-only,
    .p-inputnumber-input {
      width: 2.357rem !important;
      text-align: center;
    }
  }
}
.cart-item__delete.p-button-icon-only {
  color: var(--surface-800) !important;
}

.cart-item__delete {
  aspect-ratio: 1;
  &:focus-visible {
    color: var(--primary-color) !important;
    outline: 2px solid var(--primary-color) !important;
  }
  &:hover {
    color: var(--primary-color) !important;
  }
}
.checkout-item--border {
  border-bottom: 1px solid var(--surface-border);
}

.cart-card {
  border-color: var(--surface-border);
  border-left: none !important;
  border-right: none !important;
  border: 0.005px solid var(--surface-border);

  &--header {
    border-color: var(--surface-border);
    background: var(--surface-card);
  }
}

.cart-summary-item {
  border-bottom: 0.005px solid var(--surface-border);
}