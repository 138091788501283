.button-generator {
  padding: 2rem 0;
  position: relative;
  .p-dropdown-panel.p-component.p-connected-overlay-enter-done {
    border: 2px solid rgba(0, 0, 0, 0.5);
  }

  .p-radiobutton .p-radiobutton-box.p-highlight {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
  }
  .p-radiobutton-box {
    &:hover {
      border-color: var(--primary-dark-color) !important;
    }
  }
  .p-dropdown-items-wrapper {
    max-height: 500px;
  }
  iframe {
    border: none;
  }

  #product,
  .p-dropdown,
  .p-inputtext:enabled:hover,
  .p-inputtext:enabled:active,
  .p-inputtext:enabled:focus {
    &:hover,
    &:active,
    &:focus {
      border-color: var(--primary-dark-color);
    }
  }
  .p-dropdown:not(.p-disabled).p-focus {
    border-color: var(--primary-dark-color);
  }

  .p-inputtext:enabled:hover {
    border-color: var(--primary-color) !important;
    &:hover,
    &:active,
    &:focus {
      border-color: var(--primary-dark-color);
    }
  }
  #buttonTextDropdown {
    
  }
}
.select-market-message {
  background: #e9e9ff;
  border: 1px solid #696cff;
  color: #696cff;
  border-radius: 4px;
  max-width: max-content;
  margin: 0;
  margin-right: 1rem;
  padding: 5px 8px;
  display: inline-flex;
  text-transform: capitalize;
}
