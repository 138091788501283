footer {
  &.hide {
    display: none;
  }
  // margin-top: 8rem;
  // background-color: #1c1c1c;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // height: 100%;
  // min-height: 16rem;
  // img {
  //   height: 160px;
  // }
}
