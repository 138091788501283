.i-nav {
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1010 !important;
  background-color: #0c0c0c;
  .p-menubar-root-list {
    z-index: 500 !important;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li:nth-of-type(2):not(.p-menuitem) {
      order: -1;
    }
    .p-menubar {
      display: flex;
      justify-content: center;
      background-color: transparent;
      border: none;
      padding-top: 0;
      padding-bottom: 0;
      height: 100%;
    }
    .p-menuitem {
      text-transform: uppercase;
      transition: all 200ms ease-in-out;
      &.active {
        color: #000000 !important;
        .p-menuitem-text {
          color: black !important;
        }
        background-color: #fff !important;
      }
      > a {
        border-radius: 0px !important;
        &:hover {
          background: #fff !important;
        }
      }
      &:hover,
      &:focus-within,
      &-active {
        background-color: #fff;
        .p-menuitem-text {
          color: black !important;
        }
      }
    }
    .p-submenu-list {
      background-color: transparent;
      transition: all 200ms ease;
      max-width: 100%;
      padding: 0 !important;
      .p-menuitem {
        background-color: #fff;
        width: 100%;
        &.p-menuitem-active {
          background-color: #e9ecef !important;
        }
      }
    }
    .p-menuitem-text {
      color: white !important;
      letter-spacing: 2px;
    }
    @media only screen and (min-width: 961px) {
      li:nth-of-type(2):not(.p-menuitem) {
        order: 0;
        flex: 1;
      }
      .p-menubar-root-list {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        text-align: center;
        > .p-menuitem:last-of-type {
          position: absolute;
          right: 0;
        }
        li {
          height: 100%;
        }
        li > a {
          height: 100%;
          @media only screen and (max-width: 1035px) and (min-width: 961px) {
            padding-left: 0.5rem !important;
            padding-right: 0.5rem !important;
            font-size: 10.5px !important;
          }
          @media only screen and (min-width: 1036px) {
            font-size: 11px !important;
            padding-left: 0.8rem !important;
            padding-right: 0.8rem !important;
          }
          @media only screen and (min-width: 1300px) {
            font-size: 13.5px !important;
            padding-left: 1.2rem !important;
            padding-right: 1.2rem !important;
          }
        }
      }
    }
    @media only screen and (min-width: 1000px) {
      .p-menubar-root-list {
        justify-content: center;
      }
    }
    @media only screen and (max-width: 960px) {
      li:nth-of-type(1) {
        flex: 1;
        justify-content: center;
        padding: 0;
      }
      .p-menubar {
        position: relative;
        background-color: transparent;
        .p-menubar-root-list {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100vw;
          background-color: #171717;
          padding: 28px !important;
          > .p-menuitem:not(:last-of-type) {
            margin-bottom: 8px;
          }
          > .p-menuitem:last-of-type {
            margin-top: 50px;
            position: relative;
            &::before {
              content: '';
              position: absolute;
              width: 100vw;
              height: 1px;
              top: -29px;
              left: -28px;
              background-color: #4f4f4f;
            }
          }
        }
      }
    }
    .cart-item {
      &:hover {
        background-color: white !important;
        .pi-shopping-cart {
          color: black !important;
        }
      }
    }
  }
  .cart-wrapper {
    position: relative;
    margin: 0 0.8rem;

    i {
      z-index: 2;
      position: relative;
    }
    span {
      background-color: #52a59e;
      color: white;
      font-weight: bold;
      top: -14px;
      right: -14px;
      z-index: 2;
      opacity: 1;
      transition: opacity 200ms;
      width: 25px;
      position: absolute;
      line-height: 25px;
      border-radius: 50%;
      text-align: center;
      font-size: 12px;
      letter-spacing: 0;
      &.hide {
        opacity: 0;
      }
    }
  }
  .p-badge {
    color: white !important;
    background-color: #52a59e !important;
  }
  .p-menubar-button {
    text-decoration: none;
    position: relative;
    z-index: 1005;
    &:before {
      @media only screen and (max-width: 768px) {
        height: max-content;
      }
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      height: 72px;
      width: 48px;
      background: #0c0c0c;
      z-index: 600;
    }
    .hamburger-icon {
      position: relative;
      height: 2px;
      width: 20px;
      background: #fff;
      transition-duration: 0.3s;
      z-index: 700;
      &:before {
        position: absolute;
        content: '';
        height: 2px;
        width: 20px;
        background-color: #fff;
        top: -6px;
        left: 0;
        transition-duration: 0.3s;
      }
      &:after {
        position: absolute;
        content: '';
        height: 2px;
        width: 20px;
        background-color: #fff;
        top: 6px;
        left: 0;
        transition-duration: 0.3s;
      }
    }
  }
  @media only screen and (max-width: 960px) {
    .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
      animation: 0.5s effectIn 1;
    }
    .p-menubar:not(.p-menubar-mobile-active) .p-menubar-root-list {
      display: flex;
      flex-direction: column;
      animation: 0.5s effectOut 1;
      transform: translateY(-150%);
    }
  }
  .p-menubar.p-component {
    @media only screen and (max-width: 960px) {
      position: relative;
      z-index: 2;
      will-change: transform;
      &:before {
        background: rgba(0, 0, 0, 0);
        transition: all 200ms ease;
        content: '';
      }
      &.p-menubar-mobile-active {
        &:before {
          position: fixed;
          inset: 0 0 0 0;
          background: rgba(0, 0, 0, 0.5);
          height: 100%;
          width: 100%;
          content: '';
          z-index: 1;
        }
        .hamburger-icon {
          background-color: transparent;
          &:before {
            transform: rotate(45deg);
            transform-origin: center;
            top: 50%;
          }
          &:after {
            transform: rotate(-45deg);
            transform-origin: center;
            top: 50%;
          }
        }
      }
    }
  }
  .user-menubar {
    @media only screen and (min-width: 961px) {
      padding-right: 0 !important;
    }
    .p-submenu-list {
      .p-menuitem-active {
        > a {
          &:hover {
            background-color: #e9ecef !important;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 961px) {
    .p-menubar.p-component.p-menubar-mobile-active.user-menubar {
      .p-menubar-root-list {
        &:before {
          position: fixed;
          inset: 0 0 0 0;
          background: #0000006d;
          height: 200vh;
          width: 100vw;
          z-index: -1;
          content: '';
        }
      }
    }
  }
  @keyframes effectIn {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes effectOut {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-150%);
    }
  }

  .p-menubar.p-component {
    @media only screen and (max-width: 960px) {
      position: relative;
      z-index: 2;
      &:before {
        background: rgba(0, 0, 0, 0);
        transition: all 200ms ease;
        content: '';
      }
      &.p-menubar-mobile-active {
        &:before {
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgba(0, 0, 0, 0.5);
          height: 100%;
          width: 100%;
          content: '';
          z-index: 1;
        }
      }
    }
  }

  .p-toast {
    @media only screen and (max-width: 768px) {
      width: 20rem !important;
    }
  }

  .nav-email-item {
    .p-menuitem-link {
      @media only screen and (min-width: 961px) and (max-width: 1100) {
      }
    }
    a.p-menuitem-link .p-menuitem-text {
      @media only screen and (min-width: 960px) {
        white-space: initial;
        overflow: initial;
        text-overflow: initial;
        max-width: initial;
      }
      @media only screen and (min-width: 961px) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 10ch;
      }
      @media only screen and (min-width: 1200px) {
        max-width: 15ch;
      }
      @media only screen and (min-width: 1500px) {
        max-width: 30ch;
      }
    }
  }
  .logoLink {
    img {
      max-width: 100px;
      height: 60px;
    }
    @media only screen and (min-width: 320px) {
      img {
        max-width: 160px;
      }
    }
    @media only screen and (min-width: 768px) {
      img {
        max-width: 220px;
      }
    }
  }
  button.cart-button {
    background: transparent !important;
    &:hover, &:focus {
      background: #000;
      border: none !important;
    }
  }
}
