.digital-products-list {
  .p-dataview-content {
    .p-grid {
      display: flex;
      flex-wrap: wrap;
      margin-right: -0.5rem;
      margin-left: -0.5rem;
      margin-top: -0.5rem;
    }
  }
  .p-dataview-grid {

  }
  .p-dataview-list {
    
  }
}