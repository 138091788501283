@import 'type';
@import 'color';
@import 'buttons';
@import 'spacing';

html {
  scroll-behavior: smooth;
  .primary-border-color {
    border-color: var(--primary-color);
  }
  .primary-color {
    color: var(--primary-dark-color);
  }
}

.will-change {
  will-change: auto;
}

.p-toast {
  .cookies-toast .p-toast-icon-close {
    width: 3.5rem !important;
  }
  .p-toast-message.p-toast-message-info.cookies-toast {
    background: #171453;
    color: #fff;
    border-color: #51a59e;
    a,
    .p-toast-icon-close-icon .pi .pi-times {
      color: #51a59e;
    }
  }
}
